/* eslint-disable new-cap */
import merge from 'deepmerge'
import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import { reduceGroupedOptions } from 'react-select-async-paginate'

import log from '../../../../logging'
import { CONFIG } from '../../../../selectors'
import { buildOptionLabel, composeOptionLabel, convertArrayToObject, overwriteMerge, uniqueArray, useCustomCompareMemo } from '../../../../utils'
import Loader from '../../Loader'
import QueryBuilder from '../../QueryBuilder'
import { withResponsiveSelect } from './ResponsiveSelect'
import Label from './Label'


const ResponsiveAsyncPaginate = withResponsiveSelect(Select)

const CustomOption = props => {
  const { head, sub, img, tags } = props.data
  return <components.Option {...props} >
    <div className="customopt">
      {img && <img src={img} alt="" />}
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
      {tags &&
        <div className="select-tags">
          {tags.map((t, idx) => (
            <span className={`select-tag ${t}`} key={`tag-${idx}`}>{t}</span>
          ))}
        </div>
      }
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const customOption = props => {
  if (props.selectProps.labelformat) { return <CustomOption {...props} /> }
  return <components.Option {...props} />
}

const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
  const bottomBorder = (scrollHeight - clientHeight) / 2
  return bottomBorder < scrollTop
}


const loadIndicator = () => <Loader inline />

const AsyncSelectInput = props => {
  const {
    field,
    label,
    form,
    classes,
    labelformat,
    placeholder,
    noclear,
    multi,
    labelgrouper,
    id,
    disabled,
    readonly,
    modelname,
    endpoint,
    singular,
    plural,
    showError,
    noOptions,
    closemenuonselect,
    watch,
    params,
    metafield,
    dependents,
    optionlabel,
    optionvalue,
    labelseparator,
    cache,
    statusfield,
    options: initial_options
  } = props

  const [ options, setOptions ] = useState(initial_options ? initial_options.map(o => buildOptionLabel(props, o)) : [])
  const [ results, setResults ] = useState([])
  const [ reset, setReset ] = useState(false)
  const [ indexedResults, setIndexedResults ] = useState({})
  const [ vals, setVals ] = useState(null)
  const [ cacheuniqs, setCacheUniqs ] = useState(null)
  const [ init, setInit ] = useState(true)
  const ref = useRef(null)
  const abortController = useRef(null)

  const touchedTimeout = useRef(null)
  const statusTimeout = useRef(null)


  const setMetaFieldStatus = useCallback(values => {
    let meta_vals = null
    if (statusfield) {
      if (Array.isArray(values)) {
        meta_vals = values.map(v => getIn(cache, `${v}.${statusfield.field}`, [])).filter(v => (Array.isArray(v) ? v.length : v))
        if (meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = { ...form.status }
        meta[statusfield] = meta_vals
        statusTimeout.current = setTimeout(() => {
          form.setStatus(meta)
        }, 75)
      } else if (values) {
        meta_vals = cache[values] ? cache[values][statusfield.field] : []
        if (meta_vals && meta_vals.length) {
          meta_vals = meta_vals.flat()
        } else {
          meta_vals = null
        }
        const meta = { ...form.status }
        meta[statusfield.key] = meta_vals
        statusTimeout.current = setTimeout(() => {
          form.setStatus(meta)
        }, 75)
      } else {
        const meta = { ...form.status }
        meta[statusfield.key] = null
        statusTimeout.current = setTimeout(() => {
          form.setStatus(meta)
        }, 75)
      }
    }
  }, [ cache, statusfield, form.status ])

  const handleChange = useCallback(v => {
    let new_vals = []
    if (v) { // Is there a value? Used for clearing select
      if (Array.isArray(v)) {
        if (v.length > 0) { // Array value with values
          v.forEach(i => {
            if (Array.isArray(i.value)) {
              new_vals.push(i.value[0])
            } else {
              new_vals.push(i.value)
            }
          })
        } else {
          new_vals = v
        }
      } else if (multi) {
        if (v.value) { new_vals.push(v.value) }
      } else if (v.value) {
        new_vals = v.value
      } else if (v !== '') {
        new_vals = v
      } else {
        new_vals = null
      }
      form.setFieldValue(field.name, new_vals).then(() => {
        form.setFieldTouched(field.name)
        setMetaFieldStatus(new_vals)
        const delta = { [modelname]: {} }
        if (new_vals && !Array.isArray(new_vals)) {
          new_vals = [ new_vals ]
        }
        new_vals.filter(val => indexedResults[val]).forEach(val => {
          delta[modelname][val] = indexedResults[val]
        })
        props.actions.cacheDelta(delta)
      })
      if (dependents && !onchange) { // Unset any dependents on a field without a custom change action
        dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) })
      }
    } else { // Clear the select as there is no value
      setMetaFieldStatus(null)
      form.setFieldValue(field.name, null).then(() => form.setFieldTouched(field.name))
      if (dependents) { // Unset any dependents on a field without a custom change action
        dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) })
      }
    }
  })

  const defaultReduceOptions = useCallback((prevOptions, loadedOptions) => prevOptions.concat(loadedOptions))

  /*
  Function for merging simple options as well as option groups
  */
  const mergeOptions = useCallback(newResults => {
    if (labelgrouper) {
      const all_options = results.map(group => {
        const new_options = newResults.filter(g => g.label === group.label)
        group.options = merge(results, new_options, { arrayMerge: overwriteMerge })
        return group
      })
      return all_options
    }
    return uniqueArray([ ...results, ...newResults ], optionvalue || 'id').map(o => buildOptionLabel(props, o))
  }, [ results ])

  const loadData = async (search, prevOptions, additional) => {
    // eslint-disable-next-line react/prop-types
    let defaultOptions = []
    try {
      let queryparams = {}
      if (params) {
        const query = new QueryBuilder(params)
        queryparams = query.getAllArgs(false)
      }
      if (labelgrouper) { // If the options are grouped, iterate over each group and get the length of the inner options arrays
        queryparams.offset = prevOptions.map(group => group.options.length).reduce((a, b) => a + b, 0) // Get array lengths and reduce them into the final total
      } else {
        queryparams.offset = additional.offset
        if (queryparams.offset === 0 || queryparams.offset < 20) { delete queryparams.offset }
      }
      if (!queryparams.offset && initial_options) {
        defaultOptions = [ ...initial_options ]
      }
      const values = {
        formmodel: form.initialValues.modelname,
        modelname,
        endpoint,
        labelseparator,
        labelformat,
        labelgrouper,
        optionlabel,
        optionvalue,
        term: search,
        conflict: true,
        select: true,
        params: queryparams,
        signal: abortController.current?.signal
      }
      const response = await new Promise((resolve, reject) =>
        props.fetchMany({ values, resolve, reject, noloader: true })).catch(() =>
        ({ options: [], hasMore: false, additional }))
      let more_params = queryparams
      const r = Object.assign({}, response)
      const new_results = uniqueArray(merge(results, r.options), 'id')
      if (r.hasMore) {
        const new_query = new QueryBuilder(r.hasMore)
        more_params = new_query.getAllArgs(false)
      }
      const response_data = {
        options: r.options.map(o => buildOptionLabel({
          optionlabel,
          optionvalue,
          labelseparator,
          labelformat,
          labelgrouper
        }, o)),
        hasMore: !!r.hasMore,
        additional: more_params
      }
      if (!init) {
        setInit(true)
      }
      if (defaultOptions.length) { // Apply search to static defaults as well
        const searchLower = search ? search.toString().toLowerCase() : null
        defaultOptions = defaultOptions.filter(option => {
          if (searchLower) {
            return option.value.toString().toLowerCase().includes(searchLower)
          }
          return true
        })
        response_data.options = uniqueArray([ ...defaultOptions, ...r.options ], optionvalue || 'id').map(o => buildOptionLabel(props, o))
      }
      if (!isEqual(results, new_results)) {
        setResults(uniqueArray(merge(results, new_results), optionvalue || 'id'))
      }
      return response_data
    } catch (e) {
      if (e.error !== 'The operation was aborted. ') {
        log.error(e)
      }
    }
    return { options: [], hasMore: false, additional }
  }
  useEffect(() => {
    abortController.current = new AbortController()
    const watchvalues = watch ? watch.map(v => form.values[v]) : []
    let new_uniqs = [ ...watchvalues ]
    if (multi) {
      new_uniqs = [ field.value ? field.value.length : 0, ...new_uniqs ]
    } else {
      new_uniqs = [ field.value || 0, ...new_uniqs ]
    }
    setCacheUniqs(new_uniqs)
    return () => {
      abortController.current?.abort()
      clearTimeout(touchedTimeout.current)
      clearTimeout(statusTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (props.options && props.options.length && !options.length) {
      const new_options = props.options.map(o => buildOptionLabel(props, o))
      if (!isEqual(options, new_options)) {
        setOptions(new_options)
      }
    }
  }, [ props.options ])


  useEffect(() => {
    if (!reset) {
      setReset(true)
    }
  }, [ useCustomCompareMemo(params), useCustomCompareMemo(cacheuniqs) ])


  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [ reset ])

  useEffect(() => {
    const watchvalues = watch ? watch.map(v => getIn(form.values, v)) : []
    let new_uniqs = [ ...watchvalues ]
    if (multi) {
      new_uniqs = [ field.value ? field.value.length : 0, ...new_uniqs ]
    } else {
      new_uniqs = [ field.value || 0, ...new_uniqs ]
    }
    setCacheUniqs(new_uniqs)
  }, [ useCustomCompareMemo(form.values) ])

  useEffect(() => {
    if (metafield) {
      setMetaFieldStatus(field.value)
    }
  }, [ useCustomCompareMemo(cacheuniqs) ])

  useEffect(() => {
    const new_indexedResults = convertArrayToObject(results, props.optionvalue || 'id')
    if (!isEqual(indexedResults, new_indexedResults)) {
      setIndexedResults(new_indexedResults)
    }
  }, [ results ])

  useEffect(() => {
    if (!vals
      && (
        (!Array.isArray(field.value) && field.value)
        || (Array.isArray(field.value) && field.value.length)
      )
    ) {
      let queryparams = {}
      if (params) {
        const query = new QueryBuilder(params)
        queryparams = query.getAllArgs(false)
      }
      if (field.value) {
        queryparams[`${optionvalue ? optionvalue : 'id'}__in`] = field.value
      } else {
        delete queryparams[`${optionvalue ? optionvalue : 'id'}__in`]
      }
      if (queryparams.id__in === 'default') {
        delete queryparams.id__in
      }
      const values = {
        formmodel: form.initialValues.modelname,
        modelname,
        endpoint,
        labelseparator,
        labelformat,
        labelgrouper,
        optionlabel,
        optionvalue,
        conflict: true,
        select: true,
        params: queryparams,
        signal: abortController.current?.signal
      }
      new Promise((resolve, reject) =>
        props.fetchMany({ values, resolve, reject, noloader: true })).then(r => {
        const delta = { [modelname]: {} }
        r.options.forEach(o => { delta[modelname][o.id] = o })
        props.actions.cacheDelta(delta)
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
  }, [ field.value, vals ])

  useEffect(() => {
    let new_vals
    if (options.length && field.value) { // handle passing in of default values
      // No need to compose option labels here becuase they're already in the correct format.
      if (Array.isArray(field.value)) {
        new_vals = options.filter(o => {
          if (o.value) {
            return field.value.includes(o.value)
          }
          if (optionvalue) {
            return field.value.includes(getIn(o, optionvalue || 'id'))
          }
          return false
        })
      } else {
        new_vals = options.filter(o => {
          if (o.value) {
            return o.value === field.value
          }
          if (optionvalue) {
            return field.value.includes(getIn(o, optionvalue || 'id'))
          }
          return false
        })
      }
    }
    if (
      (!new_vals || (Array.isArray(new_vals) && !new_vals.length))
      && (Object.keys(indexedResults).length || (cache && Object.keys(cache).length))
    ) {
      new_vals = composeOptionLabel({
        ...cache,
        ...indexedResults
      }, field.value, optionlabel, labelseparator, optionvalue)
    }
    if (!isEqual(new_vals, vals)) {
      setVals(new_vals)
    }
    if (props.updateTemplates) {
      props.updateTemplates(mergeOptions(results))
    }
  }, [ results, options, cache, indexedResults, field.value, params ])

  const { name } = field
  if (!field.name) { return null }
  return (
    <div
      id={id}
      className={`selectinput asyncselectinput form-group ${classes ? classes : ''}`}
      ref={ref}>
      {label && label.length > 0 &&
        <Label htmlFor={name}>
          {label}
        </Label>
      }
      <div className="forminput">
        <ResponsiveAsyncPaginate
          key={`as-${name}`}
          debounceTimeout={300}
          className={'react-select'}
          classNamePrefix="react-select"
          isMulti={multi}
          name={field.name}
          inputId={name}
          form={form}
          field={field}
          isClearable={noclear ? false : true}
          isDisabled={readonly || disabled ? true : false}
          loadOptions={loadData}
          defaultOptions
          options={options}
          onChange={props.onChange || handleChange}
          value={vals}
          labelformat={labelformat}
          shouldLoadMore={shouldLoadMore}
          reduceOptions={labelgrouper ? reduceGroupedOptions : defaultReduceOptions}
          placeholder={placeholder}
          onMenuClose={() => setReset(true)}
          noOptionsMessage={ () => {
            if (noOptions) {
              return noOptions
            }
            if (modelname) {
              if ([ 'residential', 'holiday', 'commercial', 'estate', 'projects' ].includes(modelname)) {
                return `No ${plural} found`
              }
              if (plural.slice(0, plural.length - 1) === singular) { return `No ${plural} found` }
              return `No ${plural} found in ${singular}`
            }
            return 'No options found'
          }
          }
          components={{
            LoadingIndicator: loadIndicator,
            Option: customOption
          }}
          cacheUniqs={[ reset ]}
          additional={{ offset: 0 }}
          closeMenuOnSelect={closemenuonselect}
          blurInputOnSelect={closemenuonselect}
          onSelectResetsInput={closemenuonselect}
          backspaceRemovesValue={closemenuonselect}
        />
        <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        {showError ? <div className="error">{form.errors[field.name]}</div> : null}
      </div>
    </div>
  )
}

AsyncSelectInput.propTypes = {
  fetchMany: PropTypes.func.isRequired,
  modelname: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  actions: PropTypes.object,
  noclear: PropTypes.bool,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  dependents: PropTypes.array,
  options: PropTypes.array,
  querymodel: PropTypes.string,
  onchange: PropTypes.string,
  noOptions: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelgrouper: PropTypes.string,
  labelformat: PropTypes.object,
  watch: PropTypes.array,
  params: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  metafield: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  included_search: PropTypes.string,
  statusfield: PropTypes.object,
  labelseparator: PropTypes.string,
  optionvalue: PropTypes.string,
  placeholder: PropTypes.string,
  optionlabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  classes: PropTypes.string,
  endpoint: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  onChange: PropTypes.func,
  singular: PropTypes.string,
  plural: PropTypes.string,
  updateTemplates: PropTypes.func,
  showError: PropTypes.bool,
  closemenuonselect: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const { modelname, querymodel } = ownProps
  const queryconfig = querymodel ? CONFIG(state, querymodel) : CONFIG(state, modelname)
  const modelconfig = CONFIG(state, modelname)
  return {
    singular: queryconfig.get('singular'),
    plural: modelconfig.get('plural')
  }
}

export default connect(mapStateToProps, null)(AsyncSelectInput)
