import * as types from './actiontypes'

/*
 *  UI
*/

export const hideLoader = () => ({
  type: types.HIDE_LOADER
})

/*
 *  Login/Reset
*/
export const resetSuccess = () => ({
  type: types.RESET_SUCCESS
})

export const sendReset = data => ({
  type: types.SEND_RESET,
  data
})

export const sendActivation = data => ({
  type: types.SEND_ACTIVATION,
  data
})

export const activateUser = data => ({
  type: types.ACTIVATE_USER,
  data
})

export const resetPass = data => ({
  type: types.DO_RESET,
  data
})

export const doLogin = data => ({
  type: types.DO_LOGIN,
  data
})

export const doLogout = data => ({
  type: types.DO_LOGOUT,
  data
})

export const authFail = () => ({
  type: types.AUTH_FAIL
})

/*
 *  Agent Selector
*/

export const unselectAgent = () => ({
  type: types.UNSELECT_AGENT
})

export const selectAgent = (agent, user) => ({
  type: types.SELECT_AGENT,
  agent,
  user
})


/*
 *  Token Hydrate/Keep Alive
*/

export const reToke = () => ({
  type: types.RE_TOKE
})

export const renewToken = data => ({
  type: types.RENEW_TOKEN,
  data
})

export const applyRetoke = (user, agents, redirect = false, resolve, reject) => ({
  type: types.APPLY_RETOKE,
  agents,
  user,
  redirect,
  resolve,
  reject
})

export const loadToke = (data, noloader = null) => ({
  type: types.LOAD_TOKE,
  data,
  noloader
})

/*
 *  Version checking
*/

export const checkVersion = () => ({
  type: types.CHECK_VERSION
})

/*
 *  Autosave
*/

export const autosaveForm = data => ({
  type: types.AUTOSAVE_FORM,
  data
})

export const autosaveCheck = data => ({
  type: types.AUTOSAVE_CHECK,
  data
})

export const autosaveApply = data => ({
  type: types.AUTOSAVE_APPLY,
  data
})

export const autosaveDiscard = data => ({
  type: types.AUTOSAVE_DISCARD,
  data
})

/*
 *  UI
*/

export const removeModal = () => ({
  type: types.REMOVE_MODAL
})

export const toggleNav = () => ({
  type: types.TOGGLE_NAV
})

export const openNav = () => ({
  type: types.OPEN_NAV
})

export const closeNav = () => ({
  type: types.CLOSE_NAV
})

export const toggleMenu = () => ({
  type: types.TOGGLE_MENU
})

export const closeMenu = () => ({
  type: types.CLOSE_MENU
})

export const toggleAlerts = () => ({
  type: types.TOGGLE_ALERTS
})

export const toggleManager = () => ({
  type: types.TOGGLE_MANAGER
})

export const registerRedirect = url => ({
  type: types.REGISTER_REDIRECT,
  url
})

export const unregisterRedirect = () => ({
  type: types.UNREGISTER_REDIRECT
})

export const mountMenu = menu => ({
  type: types.MOUNT_MENU,
  menu
})

export const unmountMenu = () => ({
  type: types.UNMOUNT_MENU
})

export const notifyUser = data => ({
  type: types.NOTIFY,
  data
})

export const dismissNotice = idx => ({
  type: types.DISMISS_NOTICE,
  idx
})

export const toggleWideSidebar = name => ({
  type: types.TOGGLE_WIDE_SIDEBAR,
  name
})

export const fetchDeeds = data => ({
  type: types.FETCH_DEEDS,
  data
})

export const fetchTemplateConfig = data => ({
  type: types.FETCH_TEMPLATE_CONFIG,
  data
})

export const fetchPageTemplateConfig = data => ({
  type: types.FETCH_PAGE_TEMPLATE_CONFIG,
  data
})

export const updateTemplatePreview = data => ({
  type: types.UPDATE_TEMPLATE_PREVIEW,
  data
})

/*
 *  Server Requests
 *  - General
*/

export const fetchOne = (
  modelname,
  id,
  resolve = false,
  reject = false,
  wait = false,
  noloader = false,
  signal = false) =>
  ({
    type: types.FETCH_ONE,
    modelname,
    id,
    resolve,
    reject,
    wait,
    noloader,
    signal
  })

export const fetchMany = (data, resolve = false, reject = false) => ({
  type: types.FETCH_MANY,
  data,
  resolve,
  reject
})

/*
 *  Server Requests
 *  - Uploads/Images/Files
*/

export const fetchFile = id => ({
  type: types.FETCH_FILE,
  id
})

export const uploadFile = data => ({
  type: types.UPLOAD_FILE,
  data
})

export const changeCaption = data => ({
  type: types.CHANGE_CAPTION,
  data
})

export const changeDate = data => ({
  type: types.CHANGE_DATE,
  data
})

export const rotateImage = data => ({
  type: types.ROTATE_IMAGE,
  data
})

/*
 *  Server Requests
 *  - Activity
*/

export const fetchActivity = data => ({
  type: types.FETCH_ACTIVITY,
  data
})

/*
 *  Server Requests
 *  - Feed Logs
*/

export const fetchFeedLogs = data => ({
  type: types.FETCH_FEED_LOGS,
  data
})

/*
 *  Server Requests
 *  - Table Manager
*/

export const createPreference = data => ({
  type: types.CREATE_PREFERENCE,
  data
})

export const updatePreference = data => ({
  type: types.UPDATE_PREFERENCE,
  data
})

export const deletePreference = data => ({
  type: types.DELETE_PREFERENCE,
  data
})

/*
 *  Server Requests
 *  - Model CRUD
*/
export const bulkEditModel = data => ({
  type: types.BULK_EDIT_MODEL,
  data
})

export const updateModel = data => ({
  type: types.UPDATE_MODEL,
  data
})

export const updateHomePage = data => ({
  type: types.UPDATE_HOME_PAGE,
  data
})


export const createModel = data => ({
  type: types.CREATE_MODEL,
  data
})

export const deleteModel = data => ({
  type: types.DELETE_MODEL,
  data
})

export const checkDelete = data => ({
  type: types.CHECK_DELETE_MODEL,
  data
})

export const mergeModel = data => ({
  type: types.MERGE_MODEL,
  data
})

/*
 *  Data Tables
 *  - Record Selections
*/

export const selectOne = data => ({
  type: types.SELECT_ONE,
  ...data
})

export const selectAll = modelname => ({
  type: types.SELECT_ALL,
  modelname
})

export const selectNone = () => ({
  type: types.SELECT_NONE
})

/*
 *  Data Tables
 *  - Table Manager
*/

export const addTableField = data => ({
  type: types.ADD_TABLE_CONFIG_FIELD,
  data
})

export const removeTableField = data => ({
  type: types.REMOVE_TABLE_CONFIG_FIELD,
  data
})

export const updateTableConfig = (modelname, data) => ({
  type: types.UPDATE_TABLE_CONFIG,
  modelname,
  data
})

/*
 *  Websockets
*/

export const connectWebsocket = () => ({
  type: types.CONNECT_WEBSOCKET
})

export const disconnectWebsocket = () => ({
  type: types.DISCONNECT_WEBSOCKET
})

export const unregisterWebsocket = ws => ({
  type: types.UNREGISTER_WEBSOCKET,
  ws
})

export const pushAlert = data => ({
  type: types.PUSH_ALERT,
  data
})

export const pushMessage = data => ({
  type: types.PUSH_MESSAGE,
  data
})

export const dismissAlert = data => ({
  type: types.DISMISS_ALERT,
  data
})

export const exportData = data => ({
  type: types.EXPORT_DATA,
  data
})

export const downloadImages = data => ({
  type: types.DOWNLOAD_IMAGES,
  data
})

/*
* Listings
*/

export const generateQR = data => ({
  type: types.GENERATE_QR,
  data
})

export const fetchMatches = data => ({
  type: types.FETCH_MATCHES,
  data
})

export const fetchHighlights = data => ({
  type: types.FETCH_HIGHLIGHTS,
  data
})

export const highlightMatch = data => ({
  type: types.HIGHLIGHT_MATCH,
  data
})

export const unhighlightMatch = data => ({
  type: types.UNHIGHLIGHT_MATCH,
  data
})

export const alertAgentPropertyLead = data => ({
  type: types.ALERT_AGENTPROPERTYLEAD,
  data
})

export const notifyReferralRequest = data => ({
  type: types.NOTIFY_REFERRALREQUEST,
  data
})

export const createPortalConfig = data => ({
  type: types.CREATE_PORTAL_CONFIG,
  data
})

export const syndicatePortalItem = data => ({
  type: types.SYNDICATE_PORTAL_ITEM,
  data
})

export const syndicateItems = data => ({
  type: types.SYNDICATE_ITEMS,
  data
})

export const fetchPortalLogs = (portalid, modelid, modelname) => ({
  type: types.FETCH_PORTAL_LOGS,
  portalid: portalid,
  modelname: modelname,
  modelid: modelid
})

export const fetchProfileMatches = (id, resolve, reject) => ({
  type: types.FETCH_PROFILE_MATCHES,
  id,
  resolve,
  reject
})

export const toggleSitePortal = data => ({
  type: types.TOGGLE_SITE_PORTAL,
  data
})

export const createSitePortal = data => ({
  type: types.CREATE_SITE_PORTAL,
  data
})

export const createBranchPortalConfig = data => ({
  type: types.CREATE_BRANCH_PORTAL_CONFIG,
  ...data
})

export const deleteBranchPortalConfig = data => ({
  type: types.DELETE_BRANCH_PORTAL_CONFIG,
  ...data
})

export const updateBranchPortalConfig = data => ({
  type: types.UPDATE_BRANCH_PORTAL_CONFIG,
  ...data
})

export const generateBrochure = data => ({
  type: types.GENERATE_BROCHURE,
  data
})

export const emailProfileMatches = data => ({
  type: types.EMAIL_PROFILE_MATCHES,
  data
})

export const emailTemplate = data => ({
  type: types.EMAIL_TEMPLATE,
  data
})

export const fetchLocations = data => ({
  type: types.FETCH_LOCATIONS,
  data
})

export const fetchServicedAttributes = data => ({
  type: types.FETCH_SERVICED_ATTRIBUTES,
  data
})

export const fetchReferralContactMatch = data => ({
  type: types.FETCH_REFERRAL_CONTACT_MATCH,
  data
})

export const getLeadsAnalysisData = data => ({
  type: types.GET_LEADS_ANALYSIS,
  data
})

export const downloadLeadsAnalysis = data => ({
  type: types.GET_LEADS_ANALYSIS,
  data: {
    ...data,
    args: {
      action: 'brochure',
      template: 'leads-analysis-report'
    }
  }
})

export const getLeadsBreakdown = data => ({
  type: types.GET_LEADS_BREAKDOWN,
  data
})

export const fetchListingHistory = data => ({
  type: types.FETCH_LISTING_HISTORY,
  data
})

export const fetchAgentStatistics = data => ({
  type: types.FETCH_AGENT_STATISTICS,
  data
})

export const fetchBranchStatistics = data => ({
  type: types.FETCH_BRANCH_STATISTICS,
  data
})

export const fetchListingAnalysis = data => ({
  type: types.FETCH_LISTING_ANALYSIS,
  data
})

export const getAlertsBreakdown = data => ({
  type: types.GET_ALERTS_BREAKDOWN,
  data
})

export const createLeadInteraction = data => ({
  type: types.CREATE_LEAD_INTERACTION,
  data
})

export const updateLeadInteraction = data => ({
  type: types.UPDATE_LEAD_INTERACTION,
  data
})

export const fetchViewingFeedback = data => ({
  type: types.FETCH_VIEWING_FEEDBACK,
  data
})

export const recalculateCommission = data => ({
  type: types.RECALCULATE_COMMISSION,
  data
})

export const fetchP24Urls = (data, resolve, reject) => ({
  type: types.FETCH_P24_URLS,
  data,
  resolve,
  reject
})

export const fetchCleverCompose = (data, resolve, reject) => ({
  type: types.FETCH_CLEVER_COMPOSE,
  data,
  resolve,
  reject
})

export const fetchVacancyPro = (data, resolve, reject) => ({
  type: types.FETCH_VACANCY_PRO,
  data,
  resolve,
  reject
})

export const cacheDelta = delta => ({
  type: types.CACHE_DELTA,
  delta
})

export const calculateLeaseValue = (field, form) => {
  // handle different incomming structures
  if (field && form) {
    return {
      type: types.CALCULATE_LEASE_VALUE,
      data: {
        field,
        form
      }
    }
  } else if (field.field && field.form) {
    return {
      type: types.CALCULATE_LEASE_VALUE,
      data: {
        field: field.field,
        form: field.form
      }
    }
  }
  return {
    type: types.CALCULATE_LEASE_VALUE,
    data: {
      field,
      form
    }
  }
}

export const fetchListingCounts = (data, resolve, reject) => ({
  type: types.FETCH_LISTING_COUNTS,
  data,
  resolve,
  reject
})

export const fetchVacancyProSuburbs = (data, resolve, reject) => ({
  type: types.FETCH_VACANCY_PRO_SUBURBS,
  data,
  resolve,
  reject
})

export const sendNewsletterTest = data => ({
  type: types.SEND_NEWSLETTER_TEST,
  data
})

export const loginCreditCheck = data => ({
  type: types.LOGIN_CREDIT_CHECK,
  data
})

export const fetchCreditCheck = data => ({
  type: types.FETCH_CREDIT_CHECK,
  data
})

export const fetchCreditCheckModules = data => ({
  type: types.FETCH_CREDIT_CHECK_MODULES,
  data
})

export const resendApplication = data => ({
  type: types.RESEND_APPLICATION,
  data
})

export const copyTo = data => data

export const copyToDisplayOnBranches = (field, form) => ({
  type: types.COPY_TO_DISPLAY_ON_BRANCHES,
  data: {
    field,
    form
  }
})
