/* eslint-disable new-cap */
import { formatDistanceToNow } from 'date-fns'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { CONFIGS, SETTINGS, MINUSER } from '../../selectors'
import { convertArrayToObject, groupBy, title, valueFormat, uniqueArray } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import { Button } from '../ui/Button'
import { Scrollbar } from '../ui/Scrollbars'
import Loader from '../common/Loader'


let worker
const NotificationsWidget = props => {
  const abortController = useRef(new AbortController())
  const notificationtypes = [
    { value: 'birthday', label: 'Birthdays' },
    { value: 'branch-update', label: 'Branch Updates' },
    { value: 'listing-update', label: 'Listing Updates' },
    { value: 'sale-notice', label: 'Sale Notifications' },
    { value: 'user-update', label: 'User Updates' }
  ]

  const [ agents, setAgents ] = useState({})
  const [ cache, setCache ] = useState({})
  const [ data, setData ] = useState([])
  const [ events, setEvents ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ mounted, setMounted ] = useState(true)
  const [ notificationtype, setNotificationType ] = useState('')

  const shouldLoadUser = useCallback(() => {
    const { configs, actions } = props
    const fetch_agents = data.map(event => event.agent).filter(agent_id => !getIn(agents, agent_id))
    if (fetch_agents.length) {
      new Promise((resolve, reject) => actions.fetchMany({
        noloader: 1,
        values: {
          modelname: 'agents',
          get_all: 1,
          params: {
            id__in: uniqueArray(fetch_agents),
            meta_fields: configs.agents.fields.filter(f => getIn(f, 'metafield', getIn(f, 'modelname'))).map(f => getIn(f, 'name')),
            limit: 50
          },
          signal: abortController.current.signal
        },
        resolve,
        reject
      })).then(r => {
        if (mounted) {
          setAgents(convertArrayToObject(r.options, 'id'))
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
  })

  const fetchRecords = useCallback(() => {
    const { actions, configs } = props
    const model_groups = groupBy(data, 'model')
    const promises = []
    Object.keys(model_groups).forEach(model => {
      const config = getIn(
        configs,
        model,
        getIn(configs, Object.keys(configs).find(m => m.indexOf(model) !== -1 && m.indexOf('syndication') === -1 && m.indexOf('notification') === -1))
      )
      if (getIn(configs, `${config.modelname}.endpoint.read`)) {
        const ids = model_groups[model].map(e => e.obj_id)
        promises.push(new Promise((resolve, reject) => actions.fetchMany({
          noloader: 1,
          values: {
            modelname: config.modelname,
            get_all: 1,
            params: {
              id__in: uniqueArray(ids),
              meta_fields: config.fields.filter(f => getIn(f, 'metafield', getIn(f, 'modelname'))).map(f => getIn(f, 'name')),
              limit: 50
            },
            signal: abortController.current.signal
          },
          resolve,
          reject
        })).then(r => ({
          config,
          result: r
        })).catch(e => {
          if (e.status !== 408) { console.error(e) }
        }))
      }
    })
    Promise.allSettled(promises).then(results => {
      const c = { ...cache }
      results.forEach(r => {
        if (r.value) { // Handle timeouts / connection issues
          const { config, result } = r.value
          c[config.modelname] = convertArrayToObject(result.options, 'id')
        }
      })
      if (mounted) {
        setCache(c)
      }
    })
  })

  const processChanges = useCallback((kind, lhs, rhs, label) => {
    if (kind === 'N' || [ null, '' ].includes(lhs)) {
      if (!rhs) { return null }
      if ([ 'ul', 'a' ].includes(getIn(rhs, 'type'))) {
        return `[${label}] was set to <strong>${Array.isArray(rhs) ? rhs.join(', ') : rhs}</strong>`
      }
      return `[${label}] was set to <strong>${Array.isArray(rhs) ? rhs.join(', ') : rhs}</strong>`
    } else if (kind === 'N' || [ null, '' ].includes(rhs)) {
      if (!lhs) { return null }
      if ([ 'ul', 'a' ].includes(getIn(lhs, 'type'))) {
        return `${label}] - <strong>${Array.isArray(lhs) ? lhs.join(', ') : lhs}</strong> was removed`
      }
      return `[${label}] <strong>${Array.isArray(lhs) ? lhs.join(', ') : lhs}</strong> was removed`
    } else if (kind === 'E') {
      if ([ 'ul', 'a' ].includes(getIn(lhs, 'type')) || [ 'ul', 'a' ].includes(getIn(rhs, 'type'))) {
        return `${label}] was updated from <strong>${Array.isArray(lhs) ? lhs.join(', ') : lhs}</strong> to <strong>${Array.isArray(rhs) ? rhs.join(', ') : rhs}</strong>`
      }
      return `[${label}] was updated from <strong>${Array.isArray(lhs) ? lhs.join(', ') : lhs}</strong> to <strong>${Array.isArray(rhs) ? rhs.join(', ') : rhs}</strong>`
    }
    return null
  })

  const formatValue = useCallback((model, value, field) => {
    const { settings } = props
    let val = value
    if (field.format && ![ 'profile_photo', 'listing_popup', 'contact_popup', 'image', 'whatsapp', 'domstring' ].includes(field.format)) {
      if ([ 'currency', 'currencyabbr', 'price_range', 'listing_popup' ].includes(field.format)) {
        field.currency = settings.default_currency
      }
      val = valueFormat(field.format, value, field)
      if (typeof val === 'object') { val = value }
    }
    if (value && (field.modelname || field.metafield) && (cache[field.modelname] || getIn(model, `meta.${field.name}`))) {
      if (Array.isArray(value)) {
        val = value.map(v => {
          const related_model = getIn(cache, `${field.modelname}.${v}`, getIn(model, `meta.${field.name}.${v}`))
          if (!related_model) { return v }
          if (Array.isArray(field.optionlabel)) {
            const vals = field.optionlabel.map(l => related_model[l])
            return vals.join(field.labelseparator || ', ')
          } else if (field.optionlabel) {
            return related_model[field.optionlabel]
          }
          return null
        }).filter(v => v).join(', ')
      } else {
        const related_model = getIn(cache, `${field.modelname}.${value}`, getIn(model, `meta.${field.name}`))
        if (!related_model) { return value }
        if (Array.isArray(field.optionlabel)) {
          const vals = field.optionlabel.map(l => related_model[l])
          val = vals.join(field.labelseparator || ', ')
        } else if (field.optionlabel) {
          val = related_model[field.optionlabel]
        }
      }
    }
    return val
  })

  const getChanges = () => {
    const { user: curuser, configs } = props
    if (!worker) {
      worker = new Worker(new URL('./NotificationsWidget.worker.js', import.meta.url))
    }

    worker.postMessage({
      data,
      user: curuser,
      configs,
      cache,
      agents
    })
    worker.addEventListener('message', result => {
      const new_events = result.data.map(change => {
        const { event, model, changes: processed, field_count, agent, config } = change
        if (event.activity_type === 'birthday') {
          return (
            <div key={`event-${event.id}`} className="notification">
              <h4 className="notification-heading">Birthday</h4>
              <div className="notification-subject">{title(config.singular)} <Button component={NavLink} to={`/secure/${config.modelname}/${event.obj_id}/details`} className="has-link">{Array.isArray(config.singlekey) ? config.singlekey.map(k => getIn(model, k)).join(' ') : getIn(model, config.singlekey)}</Button> celebrated their birthday.</div>
              <div className="notification-date">{valueFormat('datetime', event.created)}</div>
            </div>
          )
        }
        const processed_changes = processed.map((c, cidx) => c.map((fc, fcidx) => processChanges(fc.kind, formatValue(model, fc.lhs, config), formatValue(model, fc.rhs, config), fc.label, `change-${cidx}-${fcidx}`)))
        const clean_changes = processed_changes.flat(2).filter(c => c).join(', ').replace(/[\n\r\t]/gi, '')
        if (!clean_changes && !field_count) { return null }
        const body = `${clean_changes} ${field_count ? `${clean_changes ? 'and ' : ''}<strong>${field_count}</strong> ${clean_changes ? 'other ' : ''}field${field_count > 1 ? 's were' : ' was'} ${event.activity_type === 'update-item' ? 'changed' : 'set'}` : ''} ${event.agent && agent && event.user ? `by ${getIn(agent, 'first_name', '')} ${getIn(agent, 'last_name', '')}` : ''} ${!event.agent && event.user ? 'by System User' : ''} ${!event.agent && !event.user ? 'by Automated System' : ''}`
        return (
          <div key={`event-${event.id}`} className="notification">
            <h4 className="notification-heading">{title(config.singular)} {event.activity_type === 'update-item' ? 'Updated' : 'Added'}</h4>
            <div className="notification-subject">{title(config.singular)} <Button component={NavLink} to={`/secure/${config.modelname}/${event.obj_id}/details`} className="has-link">{Array.isArray(config.singlekey) ? config.singlekey.map(k => getIn(model, k)).join(' ') : getIn(model, config.singlekey)}</Button> {event.activity_type === 'update-item' ? 'Updated' : 'Added'}</div>
            <div className="notification-body">{valueFormat('linebreakdomstring', body)}</div>
            <div className="notification-date">{valueFormat('datetime', event.created)} ({formatDistanceToNow(new Date(event.created).getTime(), { addSuffix: true })})</div>
            <div className="notification-actions"><Button component={NavLink} to={`/secure/${config.modelname}/${event.obj_id}/activity`} className="has-link secondary">View Changes</Button></div>
          </div>
        )
      })
      if (!isEqual(events, new_events)) { setEvents(new_events) }
    })
  }

  const filterNotifications = useCallback(() => {
    const { actions } = props
    setLoading(true)
    const params = {
      limit: 50,
      order_by: '-created',
      no_count: 1
    }
    let model
    switch (notificationtype) {
      case 'branch-update':
        model = 'branches'
        break
      case 'listing-update':
        model = 'listings'
        break
      case 'user-update':
        model = 'agents'
        break
      case 'sale-notice':
        model = 'listings'
        break
      case 'birthday':
        model = 'birthdays'
        break
      default:
        break
    }
    if (notificationtype === 'sale-notice' && model === 'listings') {
      params.diff__has_key = 'status'
      params.diff__status__1 = 'Sold'
    }

    new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'activity',
        endpoint: {
          read: `/activities/api/v1/activity/dashboard${model ? `/${model}` : ''}`
        },
        params,
        signal: abortController.current.signal
      },
      resolve,
      reject
    })).then(r => {
      if (mounted) {
        setData(r.options)
        setLoading(false)
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
  })

  useEffect(() => {
    filterNotifications()
    setMounted(true)
    return () => {
      setMounted(false)
      worker?.terminate()
      worker = null
      abortController.current.abort()
    }
  }, [])

  useEffect(() => {
    shouldLoadUser()
    fetchRecords()
    getChanges()
  }, [ data ])

  useEffect(() => {
    getChanges()
  }, [ cache ])

  useEffect(() => {
    filterNotifications()
  }, [ notificationtype ])

  return (
    <Card
      id="notifications-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <h3>Notifications</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <div className="filter-branch">
              <InlineSelect
                id="notification_type"
                name="notification_type"
                className="inline-select"
                classNamePrefix="inline"
                options={[ { label: 'Select Types', value: '' }, ...notificationtypes ]}
                defaultValue={{ label: 'All Notifications', value: '' }}
                selectedValue={notificationtype}
                onChange={e => setNotificationType(e.value)}
              />
            </div>
          </div>
        </>
      }
      body={loading ? (
        <div className="empty flex-container" style={{ height: 340 }}><Loader inline className="large" /></div>
      ) : (
        <Scrollbar style={{ height: 340 }}>
          {events.map(event => event)}
        </Scrollbar>
      )}
    />
  )
}


NotificationsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object,
  settings: PropTypes.object
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  return ({
    user,
    settings: SETTINGS(state),
    configs: CONFIGS(state)
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(NotificationsWidget))
