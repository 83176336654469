/* eslint-disable new-cap */
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'

import { MINUSER, UI, PORTALS, SITE, CACHEDMODELID, CACHE, GLOBALPORTALS, CONFIG, ADDONS, MODELNAME, MODELACTION, MODELID, MODELSEARCH } from '../../selectors'
import ModelAdd from '../../components/common/ModelAdd'
import { getSearchParam } from '../../utils'


function mapStateToProps(state) {
  let model = Map({})
  let sourcemodel = false

  const modelname = MODELNAME(state)
  const modelid = MODELID(state)
  const modelaction = MODELACTION(state)
  const modelsearch = MODELSEARCH(state)
  const config = CONFIG(state, modelname)
  const site = SITE(state)
  const siteid = site.get('id')
  const cache = CACHE(state)
  const ui = UI(state)
  const user = MINUSER(state)
  const portals = PORTALS(state)
  const addons = ADDONS(state)
  const globalportals = GLOBALPORTALS(state, siteid)
  if (modelid && modelaction === 'duplicate') { // Duplicate config
    model = CACHEDMODELID(state, modelname, modelid)
    if (model) {
      const keys = model.keySeq().toArray()
      const dedupes = config.get('fields').filter(f => f.get('dedupe') && keys.includes(f.get('name'))).map(f => f.get('name'))
      model.keys(k => { // Delete the null items
        if (model.get(k) === null) { model = model.delete(k) }
      })
      dedupes.forEach(key => {
        if (key === 'street_number' && model.get('unit_number')) {
          return
        }
        model = model.delete(key)
      })
    }
  } else if (modelid && modelaction === 'valuation') {
    model = CACHEDMODELID(state, 'valuations', modelid)
  } else if (modelsearch) { // URL params - adding based on another model ie. create listing from sell referral
    const sourcemodelname = getSearchParam('model')
    const sourcemodelid = getSearchParam('id')
    sourcemodel = CACHEDMODELID(state, sourcemodelname, sourcemodelid)
    if (!sourcemodel) { sourcemodel = false } else { sourcemodel = sourcemodel.set('model', sourcemodelname) }
  }

  return {
    config,
    user,
    ui,
    cache,
    portals,
    globalportals: site ? globalportals : false,
    model: CACHEDMODELID(state, modelname, modelid),
    modelname,
    sourcemodel,
    addons,
    modelaction,
    modelid
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeConfig, actions, match, className } = ownProps
  return ({ ...stateProps, routeConfig, location, actions, match, className })
}

export default connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(ModelAdd))
