import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { Document, Page, pdfjs } from 'react-pdf'

import log from '../logging'
import { hasAddons, hasPermission, isConditional, overwriteMerge, parseURL, uniqueArray, sortBy, convertArrayToObject } from '../utils'
import validate from '../validate'
import Loader from './common/Loader'
import Card from './common/Card'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import SelectInput from './common/forms/inputs/Select'
import ModelActions from './common/ModelActions'
import QueryBuilder from './common/QueryBuilder'
import { Button } from './ui/Button'


// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
class BrochureGenerator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      initvals: false,
      scale: 1.0,
      default: null,
      pageNumber: 1,
      page: 1,
      numPages: 0,
      rotate: null,
      zoom: 'auto',
      scrolling: false,
      highlight: false,
      document: null,
      width: null,
      height: null,
      templates: [],
      template_config: null,
      template_cache: {},
      url: '',
      status: ''
    }
    this.isConditional = isConditional.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.addVerb = this.addVerb.bind(this)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.goToPrevPage = this.goToPrevPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.isEnter = this.isEnter.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.rePosition = this.rePosition.bind(this)
    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
    this.autoFitScale = this.autoFitScale.bind(this)
    this.setScale = this.setScale.bind(this)
    this.rotateRight = this.rotateRight.bind(this)
    this.rotateLeft = this.rotateLeft.bind(this)
    this.download = this.download.bind(this)
    this.downloadImage = this.downloadImage.bind(this)
    this.updateTemplates = this.updateTemplates.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.toggleScrolling = this.toggleScrolling.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.updateStatus = this.updateStatus.bind(this)
    this.fetchTemplates = this.fetchTemplates.bind(this)
    this.zoomLevels = [
      50,
      75,
      100,
      125,
      150,
      200,
      400
    ]
  }

  componentDidMount() {
    this.props.actions.selectNone()
    if (this.props.modelid) {
      this.props.fetchOne(this.props.modelname, this.props.modelid, false, false, true)
    }
    if (!this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.brochure.redirect, this.props.model)
      this.props.registerRedirect(redirect)
    }

    if (this.props.location.search) {
      const qs = new QueryBuilder(this.props.location.search)
      if (qs.hasParam('id__in')) {
        const initial_ids = qs.getParam('id__in')
        // eslint-disable-next-line
        new Promise((resolve, reject) => {
          const values = {
            modelname: this.props.modelname,
            params: {
              id__in: initial_ids,
              get_all: true
            }
          }
          return this.props.actions.fetchMany({ values, resolve, reject })
        })
      }
    }
    this.fetchTemplates()
    window.addEventListener('resizeAutoZoom', this.autoFitScale)
    window.addEventListener('resize', this.rePosition)
    window.addEventListener('status:change', this.updateStatus)
    this.rePosition()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, modelname } = this.props
    let initial_ids = []
    let model = 0
    let old_model = 0
    if (this.props.modelid !== prevProps.modelid) {
      this.props.fetchOne(this.props.modelname, this.props.modelid, false, false, true)
    }
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
      if (initial_ids.length) {
        model = this.props.cache[modelname][initial_ids[0]]
        old_model = prevProps.cache[modelname][initial_ids[0]]
      }
    }
    if (
      (Object.keys(this.state.template_cache).length
      && !this.state.initvals
      && this.props.model)
      || !isEqual(this.props.model, prevProps.model)
    ) { // Model has loaded after mount
      this.setState({ initvals: this.initModel() })
    }
    if (this.state.document && !prevState.document) {
      this.rePosition()
    } else if (this.state.document && prevState.document) {
      if (this.state.document._pdfInfo.fingerprint !== prevState.document._pdfInfo.fingerprint) {
        this.rePosition()
      }
    }
    if (this.state.scrolling !== prevState.scrolling) {
      this.toggleScrolling(!!this.state.scrolling)
    }
    if (!isEqual(model, old_model)) {
      this.setState({ initvals: this.initModel() })
    }
    if (this.state.rotate !== prevState.rotate) {
      this.rePosition()
    }
    if (this.state.zoom !== prevState.zoom) {
      this.rePosition()
    }
    const tab_el = document.querySelector('.viewcontent')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('status:change', this.updateStatus)
    window.removeEventListener('resizeAutoZoom', this.autoFitScale)
    window.removeEventListener('resize', this.rePosition)
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  fetchTemplates() {
    const { config } = this.props
    new Promise((resolve, reject) => this.props.actions.fetchMany({
      values: {
        modelname: 'templates',
        conflict: true,
        params: {
          template_type: 'brochure',
          order_by: 'ordinal',
          model: getIn(config, 'servicename', config.modelname),
          get_all: 1
        }
      },
      resolve,
      reject
    })).then(r => {
      const template_config = r.options[0]
      this.setState({ template_cache: convertArrayToObject(r.options, 'name'), template_config })
    })
  }

  hasPermission() {
    const { model, user, config, addons } = this.props
    if (config.addons) {
      if (!hasAddons(config.addons, addons)) { return false } // Entire module is disabled
    }
    const requiredPermissions = this.props.routeConfig.brochure.permissions
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_view_own'))
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (this.props.model && this.props.modelid) {
      if (hasPermission(hasEditAllPermissions, user.permissions)) { return true }
      if (hasPermission(hasEditOwnPermissions, user.permissions)) {
        switch (config.modelname) {
          case 'residential':
          case 'commercial':
          case 'holiday':
            if (![ 'agent', 'agent_2', 'agent_3', 'agent_4' ].map(k => getIn(model, k)).some(v => v !== user.agent.id)) {
              return false
            }
            return true
          case 'users':
          case 'leads':
            if (model.agent !== user.agent.id) { return false }
            return true
          case 'contacts':
            if (model.introduction_agent !== user.agent.id) { return false }
            return true
          case 'subscribers':
            if (model.meta.introduction_agent !== user.agent.id) { return false }
            return true
          default:
            return true
        }
      }
    }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  setStatus(status) {
    const event = new CustomEvent('status:change', { detail: status })
    window.dispatchEvent(event)
  }

  updateStatus(e) {
    setTimeout(() => {
      this.setState({ status: e.detail })
    })
  }

  updateTemplates(templates) {
    if (!isEqual(templates, this.state.templates)) {
      this.setState({ templates })
    }
  }

  toggleScrolling(isEnable) {
    if (isEnable) {
      window.addEventListener('mouseup', this.onMouseUp)
      if (!this.state.highlight) {
        window.addEventListener('mousemove', this.onMouseMove)
      }
    } else {
      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('mouseup', this.onMouseUp)
    }
  }

  autoFitScale() {
    if (this.state.document) {
      const originalWidth = 595
      const containerWidth = this.el && this.el.offsetWidth < 1020
        ? this.el.offsetWidth - 56
        : 1019
      let nextScale = -1

      if (this.el) {
        nextScale = Math.abs(containerWidth) / originalWidth
      }

      this.state.document.getPage(1).then(page => {
        const viewport = page.getViewport()
        return { width: viewport.viewBox[2], height: viewport.viewBox[3] }
      }).then(({ width, height }) => {
        if ([ 90, 270 ].includes(this.state.rotate)) {
          this.setState({ height: (this.el.offsetHeight * (width / height)) + 56 })
        } else {
          this.setState({ height: (this.el.offsetWidth * (height / width)) + 25 })
        }
      })
      this.setScale(nextScale * 100, true)
    }
  }

  setScale(scale, auto = false) {
    if (scale < 0) {
      this.autoFitScale()
      return
    }

    const nextScale = scale >= 10 ? scale : 10
    if (nextScale !== this.state.scale) {
      if (!this.state.default) {
        this.setState({ scale: scale / 100, zoom: 'auto', default: scale / 100 })
      } else {
        this.setState({ scale: scale / 100, zoom: auto ? 'auto' : scale })
      }
    }
  }

  zoomIn() {
    const { scale } = this.state
    let newScale
    if (this.state.zoom === 'auto') {
      const nextZoom = this.zoomLevels
        .reduce((prev, curr) => (Math.abs(curr - scale * 100) < Math.abs(prev - scale * 100) ? curr : prev))
      const zoomIdx = this.zoomLevels.indexOf(nextZoom)
      if (zoomIdx + 1 <= this.zoomLevels.length) {
        newScale = this.zoomLevels[zoomIdx + 1]
      }
    } else {
      const zoomIdx = this.zoomLevels.indexOf(scale * 100)
      if (zoomIdx + 1 <= this.zoomLevels.length) {
        newScale = this.zoomLevels[zoomIdx + 1]
      }
    }

    if (newScale > 1000) { return }
    if (newScale) { this.setScale(newScale) }
  }

  zoomOut() {
    const { scale } = this.state
    let newScale
    if (this.state.zoom === 'auto') {
      const nextZoom = this.zoomLevels
        .reduce((prev, curr) => (Math.abs(curr - scale * 100) < Math.abs(prev - scale * 100) ? curr : prev))
      const zoomIdx = this.zoomLevels.indexOf(nextZoom)
      if (zoomIdx - 1 >= 0) {
        newScale = this.zoomLevels[zoomIdx - 1]
      }
    } else {
      const zoomIdx = this.zoomLevels.indexOf(scale * 100)
      if (zoomIdx - 1 >= 0) {
        newScale = this.zoomLevels[zoomIdx - 1]
      }
    }

    if (newScale <= 0) { return }
    if (newScale) { this.setScale(newScale) }
  }

  rotateRight() {
    let currentRotation = this.state.rotate
    if (currentRotation + 90 > 360) {
      currentRotation = 0
    }
    if (currentRotation + 90 === 360) {
      currentRotation = -90
    }
    this.setState({ rotate: currentRotation + 90 })
  }

  rotateLeft() {
    let currentRotation = this.state.rotate
    if (currentRotation - 90 < 0) {
      currentRotation = 360
    }
    this.setState({ rotate: currentRotation - 90 })
  }

  initModel(template_config, values) { // Initialise model data for formik
    const initvals = {}
    const { model, cache, config } = this.props
    let data = { ...model }
    const { location, modelname } = this.props
    let initial_ids = []
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
      if (initial_ids.length) {
        data = cache[modelname][initial_ids[0]]
      }
    }
    // Initialise template options
    const template_list = sortBy(Object.keys(this.state.template_cache).map(k => this.state.template_cache[k]), 'ordinal')
    initvals.template = getIn(template_config, 'name', getIn(template_list, '0.name'))
    this.updateTemplates(uniqueArray(this.state.templates, 'value'))
    const brochure_config = this.state.template_cache[initvals.template]
    const brochure_fields = brochure_config.fields.map(f => {
      const field = config.fields.find(fe => fe.name === getIn(f, 'name', f).replace('override-', ''))
      if (field) {
        return merge(f, field)
      }
      return f
    })
    if (brochure_config) {
      if (!isEqual(this.state.template_config, brochure_config)) {
        this.setState({ template_config: brochure_config })
      }
      brochure_config.fields.forEach(f => {
        const name = getIn(f, 'name', f)
        const value = getIn(values, name, getIn(data, name))
        initvals[`override-${name}`] = value
        if (f.input === 'Check') {
          initvals[`override-${name}`] = false
        }
      })
    }

    Object.keys(initvals).forEach(f => {
      const field = brochure_fields.find(fe => fe.name === f.replace('override-', ''))
      if (!field) { return }

      if (field.name === 'template' && [ 'window-display-portrait', 'window-display-landscape', 'property-information' ].includes(initvals[f])) {
        return
      }
      if (field && field.modelname) {
        if (field.extraparams) { // Used for form submission
          field.params = parseURL(
            field.extraparams,
            data,
            this.props.user ? this.props.user.agent : false,
            this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false,
            this.props.cache,
            0, // index
            field.extraparamslock
          )
        }
        if (field.params) {
          const qs = new QueryBuilder(field.params)
          const params = qs.getAllArgs()
          params[`${field.optionvalue ? field.optionvalue : 'id'}__in`] = Array.isArray(initvals[f]) ? initvals[f] : [ initvals[f] ]
          const vals = {
            modelname: field.modelname,
            params
          }
          this.props.actions.fetchMany({ values: vals })
        }
      }
    })

    if (initial_ids.length) {
      Object.keys(initvals).forEach(f => {
        const field = this.props.config.brochure.fields.find(fe => fe.name === f || fe.name === f.replace('override-', ''))
        if (field && field.multiple === false) {
          delete initvals[`override-${field.name}`]
        }
        if (![ 'template', 'id__in' ].includes(f) || [ 'listing_images' ].includes(f.replace('override-', ''))) {
          delete initvals[`override-${f.replace('override-', '')}`]
        }
        if ([ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(f.replace('override-', ''))) {
          initvals[`override-${f.replace('override-', '')}`] = 'default'
        }
      })
    }
    return initvals
  }

  async downloadImage() {
    const { cache, modelname } = this.props
    const { template_config } = this.state
    const id = this.form.values.id__in[0]
    const filename = `${cache[modelname][id].web_ref} - ${template_config.display_name} - ${this.state.page}.png`
    try {
      return await this.state.document.getPage(this.state.page).then(page => {
        const scale = '1.5'
        const viewport = page.getViewport({
          scale: scale
        })
        const canvas = document.createElement('canvas')
        const canvasContext = canvas.getContext('2d')
        canvas.height = viewport.height || viewport.viewBox[3] /* viewport.height is NaN */
        canvas.width = viewport.width || viewport.viewBox[2] /* viewport.width is also NaN */
        page.render({
          canvasContext, viewport
        }).promise.then(() => {
          canvas.toBlob(blob => {
            this.downloadManager.download(blob, canvas.toDataURL(), filename)
          })
        })
      }).catch(e => {
        console.error(e)
      })
    } catch (e) {
      console.error(e)
    }
    return null
  }

  async download(event, id) {
    const { cache, modelname } = this.props
    const { template_config } = this.state
    const pdfDoc = this.state.document
    const { url } = pdfDoc._transport._params
    const filename = `${cache[modelname][id].web_ref} - ${template_config.display_name}.pdf`

    const downloadByUrl = () => {
      this.downloadManager.downloadUrl(url, filename)
    }

    try {
      const data = await this.state.document.getData()
      const blob = new Blob([ data ], { type: 'application/pdf' })
      this.downloadManager.download(blob, url, filename)
    } catch (e) {
      downloadByUrl()
    }
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) }


  addVerb(bag) {
    const { location } = this.props
    const { fields } = this.props.config
    let initial_ids = []
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
    }
    initial_ids = initial_ids.filter(k => !Number.isNaN(parseInt(k, 10)))
    // Merge field configs from model fields and brochure fields.
    // Deepmerge wasn't doing this correctly.
    const template_fields = getIn(this.state, 'template_config.fields', []) || []
    const inferred_fields = template_fields.map(f => {
      if (typeof f === 'string') {
        return fields.find(field => field.name === f)
      }
      const real_field = fields.find(field => isEqual(field.name, f.name))
      if (real_field) {
        return real_field
      }
      return f
    })
    const all_fields = uniqueArray(merge([], [
      ...inferred_fields,
      ...fields
    ], { arrayMerge: overwriteMerge }).map(f => {
      const override = this.props.config.brochure.fields.find(bf => bf && f && getIn(bf, 'name') === getIn(f, 'name'))
      if (override) { return merge(f, override, { arrayMerge: overwriteMerge }) }
      return f
    }), 'name')
    this.props.config.brochure.fields.forEach(bf => {
      const exists = all_fields.find(f => bf && f && getIn(bf, 'name') === getIn(f, 'name'))
      if (!exists) {
        all_fields.unshift(merge({}, bf))
      }
    })
    if (all_fields) {
      const brochure_fields = all_fields.filter(f => {
        if (!f) { return false }
        if (f.name === 'model' && this.props.modelname) { return false }
        if (f.name === 'id__in' && bag.values.id__in && !this.props.modelid) { return true }
        if (f.name === 'template' && (this.props.modelid || bag.values.id__in)) { return true }
        if (this.state.template_config) {
          if (initial_ids.length && f.multiple === false) {
            return false
          }
          return this.state.template_config.fields.map(field => getIn(field, 'name', field)).includes(f.name)
        }
        return false
      }).map(field => {
        const rest = this.state.template_config ? this.state.template_config.fields.find(f => getIn(f, 'name') === field.name) || {} : {}
        const props = {
          ...field,
          ...rest,
          cols: 'lg-12',
          required: field.name === 'template',
          quality: false,
          group: 'Brochure Generator',
          name: `${(this.state.template_config && this.state.template_config.fields.map(f => getIn(f, 'name', f)).includes(field.name)) || [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(field.name.replace('override-', '')) ? `override-${field.name}` : field.name}${field.verb ? `__${field.verb}` : ''}`
        }

        if (initial_ids.length > 1 && [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(field.name.replace('override-', ''))) {
          props.options = [
            {
              id: 'default',
              first_name: 'Default',
              last_name: 'Agent'
            }
          ]
          const extraparams = props.extraparams.split('&').map(p => {
            if (p.startsWith('id__in__not')) {
              const prop = p.split('=')[0]
              let vars = p.split('=:').pop().split('|')
              vars = vars.filter(v => bag.values[v] && bag.values[v] !== 'default')
              vars = vars.join('|')
              if (vars) {
                p = [ prop, vars ].join('=:')
                return p
              }
              return null
            }
            return p
          }).join('&')
          props.extraparams = extraparams
        }
        if (props.name === 'template') {
          props.updateTemplates = this.updateTemplates
        }
        return props
      })
      return <FieldGroup
        form={bag}
        card={false}
        groupname="Brochure Generator"
        match={this.props.match}
        modelid={this.props.modelid}
        config={{
          fields: brochure_fields
        }}
        fields={brochure_fields}
      />
    }
    return null
  }

  async rePosition() {
    const { zoom } = this.state

    if (zoom && zoom !== 'auto') {
      this.setScale(zoom)
    } else {
      await this.autoFitScale()
    }
  }

  onDocumentLoadSuccess(document) {
    const { numPages } = document
    document.getPage(1).then(page => {
      const viewport = page.getViewport()
      return { width: viewport.viewBox[2], height: viewport.viewBox[3] }
    }).then(({ width, height }) => {
      this.setState({
        scale: 1.0,
        rotate: null,
        zoom: 'auto',
        scrolling: false,
        highlight: false,
        numPages,
        page: 1,
        pageNumber: 1,
        document,
        width: width,
        height: (this.el.scrollWidth * (height / width)) + 25
      })
    })
  }

  goToPrevPage() {
    const newpage = parseInt(this.state.pageNumber, 10) - 1
    if (newpage > 0) {
      this.setState({ pageNumber: newpage, page: newpage })
    }
  }

  goToNextPage() {
    const newpage = parseInt(this.state.pageNumber, 10) + 1
    if (newpage <= this.state.numPages) {
      this.setState({ pageNumber: newpage, page: newpage })
    }
  }

  goToPage(page) {
    const newpage = page
    if (newpage <= this.state.numPages && newpage > 0) {
      this.setState({ pageNumber: newpage })
    }
  }

  onMouseDown(event) {
    if (!this.el) { return false }
    const { scrollLeft, scrollTop } = this.el
    this.setState({ scrolling: true, scrollLeft, scrollTop, clientX: event.clientX, clientY: event.clientY })
    return event
  }

  onMouseMove(event) {
    if (!this.el) { return false }
    const { clientX, scrollLeft, scrollTop, clientY } = this.state
    this.el.scrollLeft = scrollLeft - clientX + event.clientX
    this.el.scrollTop = scrollTop - clientY + event.clientY
    return event
  }

  onMouseUp(event) {
    if (!this.el) { return false }
    const { scrollLeft, scrollTop } = this.el
    this.setState({
      scrolling: false,
      scrollLeft,
      scrollTop,
      clientX: event.clientX,
      clientY: event.clientY
    })
    return event
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.goToPage(e.target.value)
    } // continue typing
    return true
  }

  render() {
    const { config, actions, ui, location } = this.props
    let initial_ids = [ this.props.modelid ].filter(a => a)
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
    }
    initial_ids = initial_ids.filter(k => !Number.isNaN(parseInt(k, 10)))

    return (
      <Formik
        validationSchema={validate.brochure_generator}
        validateOnChange={true}
        initialValues={{
          ...this.state.initvals,
          model: config.servicename || this.props.modelname,
          id__in: initial_ids
        }}
        enableReinitialize={true}
        onSubmit={(values, form) => new Promise((resolve, reject) => {
          form.setSubmitting(true)
          const { template, ...cleanvalues } = values
          cleanvalues.id__in = cleanvalues.id__in.join(',')
          delete cleanvalues.offset
          const params = {
            params: cleanvalues,
            modelname: this.props.modelname,
            args: {
              action: 'brochure',
              template
            },
            label: 'Brochure',
            noalert: true,
            callback: this.setStatus,
            resolve,
            reject
          }
          this.setState({ url: '' })
          return actions.exportData(params)
        }).then(r => {
          form.setSubmitting(false)
          const url = r.response.file
          form.setSubmitting(false)
          this.setState({ url })
        }).catch(e => {
          if (Object.keys(e).includes('id__in') || Object.keys(e).includes('template')) {
            form.setErrors(e.response)
          }
          form.setSubmitting(false)
          log.error(e)
        })
        }
      >{formik => {
          this.form = formik
          const template = this.state.templates.find(e => formik.values.template === e.value)
          return (
            <div id="content" className="content">
              <div className="viewhead details">
                <div className="action-bar">
                  <ModelActions
                    touched={formik.touched}
                    errors={formik.errors}
                    handleSubmit={e => {
                      this.download(e, this.form.values.id__in[0])
                    }}
                    isSubmitting={formik.isSubmitting}
                    redirectSchema={this.redirectSchema}
                    form={formik}
                    modelname={config.modelname}
                    actions={{
                      downloadImage: this.downloadImage
                    }}
                    extras={{
                      savepng: {
                        label: 'Download PNG',
                        menu: null,
                        redirect: null,
                        icon: '#icon16-Download',
                        action: 'downloadImage',
                        className: 'btn-round btn-red'
                      },
                      save: {
                        label: 'Generate Brochure',
                        menu: null,
                        redirect: null,
                        icon: '#icon16-Download',
                        action: 'submitForm',
                        className: 'btn-round btn-red'
                      }
                    }}
                    statusmsg={formik.status ? formik.status.msg : false}
                  />
                </div>
              </div>
              <div className={`view details brochures ${config.modelname}`}>
                <div className="viewcontent">
                  <div className="brochure-generator">
                    <div className='brochure-form'>
                      <Card
                        background={true}
                        header={
                          <h3>Brochure Generator</h3>
                        }
                        body={
                          <CustomForm
                            ui={ui}
                            model={this.props.model ? true : false}
                            onChange={() => {
                              if (this.state.initvals.template && this.form && !formik.touched.template) {
                                formik.setFieldTouched('template') // enable button as soon as templates are loaded
                              }
                              if (template) {
                                const template_config = this.state.template_cache[template.value]
                                if (
                                  (!this.state.template_config && template_config) ||
                                  (this.state.template_config && this.state.template_config.name !== template.value)
                                ) {
                                  this.setState({
                                    template_config,
                                    initvals: { ...this.initModel(template_config, formik.values) }
                                  })
                                }
                              }
                            }}
                            setContextForm={this.props.actions.setContextForm}
                            render={() => (
                              <div className='row'>
                                <div className='col'>
                                  {this.addVerb(formik)}
                                </div>
                              </div>
                            )}
                          />
                        }
                      />
                    </div>
                    <div className='brochure-preview'>
                      <Card
                        background={true}
                        header={
                          <h3>Brochure Preview</h3>
                        }
                        body={
                          <div className='pdfcontainer'>
                            {this.state.url ? (
                              <nav id='pdfcontainer-nav' className='pdfcontainer-nav'>
                                <div className="nav-group">
                                  <div className="pagejump">
                                    <div className="form-group page">
                                        Page
                                      <div className="forminput">
                                        <input
                                          // component="input"
                                          type="number"
                                          className="form-control form-control-lg page-jump"
                                          min={1}
                                          value={this.state.page}
                                          onKeyDown={this.isEnter}
                                          onChange={e => this.setState({ page: e.target.value })}
                                        />
                                      </div>
                                        /  {this.state.numPages}
                                    </div>
                                  </div>
                                  <Button icon="#icon16-ChevronLeft" title="Previous Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={this.state.page <= 1} onClick={this.goToPrevPage} />
                                  <Button icon="#icon16-ChevronRight" title="Next Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={this.state.page === this.state.numPages} onClick={this.goToNextPage} />
                                </div>
                                <div className="nav-group">{ /* We hide this nav-group in mobi view */ }
                                  <SelectInput
                                    id="zoom"
                                    form={formik}
                                    onChange={opt => this.setState({ zoom: opt.value }) }
                                    noclear
                                    field={{
                                      name: 'zoom',
                                      value: this.state.zoom
                                    }}
                                    options={[
                                      {
                                        value: 'auto', label: `Auto Fit (${parseFloat(this.state.default * 100).toFixed(0)}%)`
                                      },
                                      ...this.zoomLevels.map(zoom => ({ value: zoom, label: `${zoom}%` }))
                                    ]}
                                  />
                                  <Button icon="#icon24-Plus" title="Zoom In" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.zoomIn} />
                                  <Button icon="#icon24-Minus" title="Zoom Out" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.zoomOut} />
                                  <Button icon="#icon24-RotateLeft" title="Rotate CCW 90deg" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.rotateLeft} />
                                  <Button icon="#icon24-RotateRight" title="Rotate CW 90deg" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={this.rotateRight} />
                                </div>
                                <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>{(formik.dirty && Object.keys(formik.touched).length) ? 'Update Preview' : 'Preview'}</Button>
                              </nav>
                            ) : null }
                            <div ref={el => { if (el) { this.el = el } }} className={`react-pdf${this.state.highlight ? ' react-pdf__Highlighting' : ''}${(!this.state.highlight && this.state.scrolling) ? ' react-pdf__Scrolling' : ''}`} style={{ height: this.state.height }}>
                              {formik.isSubmitting ? (
                                <div className="text-center">
                                  <Loader inline className="large" onError={() => {}} throwViewError={() => {}} />
                                  <div className="message">{this.state.status ? this.state.status.response.detail : null}</div>
                                </div>
                              ) : (
                                <Document
                                  file={this.state.url}
                                  loading={<Loader inline className="large" onError={() => {}} throwViewError={() => {}} />}
                                  onLoadSuccess={this.onDocumentLoadSuccess}
                                  onMouseDown={this.onMouseDown}
                                  onScroll={this.onMouseMove}
                                  rotate={this.state.rotate}
                                  noData={(
                                    <div className="no-pdf">
                                      <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>Preview PDF</Button>
                                    </div>
                                  )}
                                >
                                  <Page
                                    pageNumber={parseInt(this.state.pageNumber, 10)}
                                    renderTextLayer={this.state.highlight}
                                    scale={this.state.scale}
                                  />
                                </Document>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } }
      </Formik>
    )
  }
}

BrochureGenerator.propTypes = {
  model: PropTypes.object,
  location: PropTypes.object,
  fetchOne: PropTypes.func,
  modelname: PropTypes.string,
  modelid: PropTypes.string,
  routeConfig: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  configs: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  registerRedirect: PropTypes.func
}

export default BrochureGenerator
