import React from 'react'
import PropTypes from 'prop-types'

import { withNoHistoryRouter } from '../../withCustomRouter'
import { Scrollbar } from '../Scrollbars'
import packageinfo from '../../../../package.json'
import MenuItem from './MenuItem'
import SubMenu from './SubMenu'


const Nav = navprops => {
  // eslint-disable-next-line no-unused-vars
  const { className, match, location, staticContext, ...props } = navprops
  const { site } = match.params
  return (
    <nav
      className={`main-sidebar${className ? ` ${className}` : ''}`}
      {...props}
    >
      <Scrollbar style={{ height: 'calc(100vh - 68px)' }}>
        <ul className="main-menu">
          <MenuItem
            icon="#icon24-Dashboard"
            to={`/secure/${site}`}
            exact
            location={location}
          >
              Dashboard
          </MenuItem>
          <SubMenu
            title="Company"
            icon="#icon24-Briefcase"
            to={'/secure/:site(\\d+)/:model(franchises|branches|agents|teams|groups)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="franchises" to={`/secure/${site}/franchises`}>Franchises</MenuItem>
            <MenuItem location={location} area="list" model="branches" to={`/secure/${site}/branches`}>Branches</MenuItem>
            <MenuItem location={location} area="list" model="agents" to={`/secure/${site}/agents`}>Users</MenuItem>
            <MenuItem location={location} area="list" model="teams" to={`/secure/${site}/teams`}>Teams</MenuItem>
            <MenuItem location={location} area="list" model="groups" to={`/secure/${site}/groups`}>User Groups</MenuItem>
          </SubMenu>
          <SubMenu
            title="Listings"
            icon="#icon24-Listings"
            to={'/secure/:site(\\d+)/:model(residential|commercial|holiday|projects|valuations)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="residential" to={`/secure/${site}/residential`}>Residential</MenuItem>
            <MenuItem location={location} area="list" model="commercial" to={`/secure/${site}/commercial`}>Commercial / Industrial</MenuItem>
            <MenuItem location={location} area="list" model="holiday" to={`/secure/${site}/holiday`}>Holiday Letting</MenuItem>
            <MenuItem location={location} area="list" model="projects" to={`/secure/${site}/projects`}>Projects</MenuItem>
            <MenuItem location={location} area="list" model="valuations" to={`/secure/${site}/valuations`}>Valuations</MenuItem>
          </SubMenu>
          <SubMenu
            title="Syndication"
            icon="#icon24-Syndication"
            to={`/secure/${site}/syndication/:model(residential|commercial|holiday|agents|branches)`}
            location={location}
          >
            <MenuItem location={location} area="list" log="branches" model="syndication" to={`/secure/${site}/syndication/branches`}>Branches</MenuItem>
            <MenuItem location={location} area="list" log="agents" model="syndication" to={`/secure/${site}/syndication/agents`}>Users</MenuItem>
            <MenuItem location={location} area="list" log="residential" model="syndication" to={`/secure/${site}/syndication/residential`}>Residential</MenuItem>
            <MenuItem location={location} area="list" log="commercial" model="syndication" to={`/secure/${site}/syndication/commercial`}>Commercial / Industrial</MenuItem>
            <MenuItem location={location} area="list" log="holiday" model="syndication" to={`/secure/${site}/syndication/holiday`}>Holiday Letting</MenuItem>
          </SubMenu>
          <SubMenu
            title="Clients"
            icon="#icon24-Clients"
            to={'/secure/:site(\\d+)/:model(contacts|leads|profiles|alerts|subscribers|referrals)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="contacts" to={`/secure/${site}/contacts`}>Contacts</MenuItem>
            <MenuItem location={location} area="list" model="profiles" to={`/secure/${site}/profiles`}>Profiles</MenuItem>
            <MenuItem location={location} area="list" model="leads" to={`/secure/${site}/leads`}>Leads</MenuItem>
            <MenuItem location={location} area="list" model="subscribers" to={`/secure/${site}/subscribers`}>Subscriptions</MenuItem>
            <MenuItem location={location} area="list" model="referrals" to={`/secure/${site}/referrals`}>Referrals</MenuItem>
          </SubMenu>
          <SubMenu
            title="Transactions"
            icon="#icon24-Valuation"
            to={'/secure/:site(\\d+)/:model(applications|offers|deals)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="offers" to={`/secure/${site}/offers`}>Offers</MenuItem>
            <MenuItem location={location} area="list" model="applications" to={`/secure/${site}/applications`}>Applications</MenuItem>
          </SubMenu>
          <SubMenu
            title="Marketing"
            icon="#icon24-Megaphone"
            to={'/secure/:site(\\d+)/:model(newsletters|newsletter-templates)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="newsletters" to={`/secure/${site}/newsletters`}>Newsletters</MenuItem>
          </SubMenu>
          <SubMenu
            title="Website"
            icon="#icon24-Website"
            to={'/secure/:site(\\d+)/:model(themesettings|articles|locationprofiles|pages|modules|navigation|pagetemplates|themes)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="themesettings" to={`/secure/${site}/themesettings`}>Theme Settings</MenuItem>
            <MenuItem location={location} area="list" model="articles" to={`/secure/${site}/articles`}>Articles</MenuItem>
            <MenuItem location={location} area="list" model="locationprofiles" to={`/secure/${site}/locationprofiles`}>Location Profiles</MenuItem>
            <MenuItem location={location} area="list" model="pages" to={`/secure/${site}/pages`}>Pages</MenuItem>
            <MenuItem location={location} area="list" model="navigation" to={`/secure/${site}/navigation`}>Navigation</MenuItem>
            <MenuItem location={location} area="list" model="modules" to={`/secure/${site}/modules`}>Modules</MenuItem>
            <MenuItem location={location} area="list" model="pagetemplates" to={`/secure/${site}/pagetemplates`}>Page Templates</MenuItem>
            <MenuItem location={location} area="list" model="themes" to={`/secure/${site}/themes`}>Themes</MenuItem>
            <MenuItem location={location} area="list" model="insights" to={`/secure/${site}/insights`}>Insights</MenuItem>
          </SubMenu>
          <SubMenu
            title="Media"
            icon="#icon24-Image"
            to={'/secure/:site(\\d+)/:model(images|documents)'}
            location={location}
          >
            <MenuItem location={location} area="list" model="documents" to={`/secure/${site}/documents`}>Documents</MenuItem>
            <MenuItem location={location} area="list" model="assets" to={`/secure/${site}/assets`}>Assets</MenuItem>
          </SubMenu>
        </ul>
        <div className="pd-logo">
          <svg viewBox="0 0 123 32"><use href="/images/glyphs.svg#glyph-PDLogo" /></svg>
          <div>v. {packageinfo.version} &copy; Prop Data {new Date().getFullYear()}</div>
        </div>
      </Scrollbar>
    </nav>
  )
}

Nav.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object
}

export default withNoHistoryRouter(React.memo(Nav))

// Nav.whyDidYouRender = true
