import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'
import { Formik, getIn } from 'formik'

// import worker from 'workerize-loader!./Activity.worker' // eslint-disable-line import/no-webpack-loader-syntax
import { withCustomRouter } from '../withCustomRouter'
import { groupBy, sortBy, uniqueArray } from '../../utils'
import { Button } from '../ui/Button'
import QueryBuilder from './QueryBuilder'
import ActivityEvent from './ActivityEvent'
import Loader from './Loader'
import FieldGroup from './forms/FieldGroup'
import CustomForm from './forms/CustomForm'


class Activity extends React.Component {
  constructor(props) {
    super(props)
    this.getEventMonth = this.getEventMonth.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.setHasEvents = this.setHasEvents.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.isEnter = this.isEnter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      offset: 0,
      scrollOffset: 0,
      loading: false,
      selected: [],
      more: false,
      hasEvents: false,
      searched: false,
      term: null,
      dates: [],
      initvals: {},
      searchValues: {}
    }
    this.load = null
    this.curmonth = null
    this.fields = [
      {
        name: 'search',
        type: 'search',
        placeholder: 'Keyword Search',
        edit: true
      },
      {
        name: 'created__date',
        id: 'created__date',
        placeholder: 'From Date',
        label: false,
        input: 'Date',
        inline: true,
        format: 'date',
        enabled: this.state.dates,
        edit: true
      }
    ]
    this.worker = new Worker(new URL('./Activity.worker.js', import.meta.url))
  }

  componentDidMount() {
    const { config, model } = this.props
    const params = {
      order_by: '-created',
      modelname: config.modelname,
      id: model.id
    }
    new Promise((resolve, reject) => this.props.fetchActivity(params, resolve, reject)).then(r => {
      const more = !!r.next
      const events = Array.isArray(r) ? r : r.results
      this.setState({ events, more, offset: events.length })
    })
    new Promise((resolve, reject) => this.props.fetchActivity({ ...params, fields: [ 'id', 'created' ], get_all: 1 }, resolve, reject)).then(r => {
      const dayGroups = groupBy(r, 'created', this.getEventDay)
      this.setState({ dates: Object.keys(dayGroups) })
    })

    const tab_el = document.querySelector('.activity')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top
      if (this.state.scrollOffset !== offset) {
        this.setState({ scrollOffset: offset })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state.events, prevState.events)) {
      this.fetchRelated()
    }
  }

  componentWillUnmount() {
    this.worker?.terminate()
    this.worker = null
  }

  fetchRelated() {
    const { config } = this.props
    const { events } = this.state
    const models_to_fetch = {
      agents: []
    }
    events.forEach(event => {
      models_to_fetch.agents.push(event.agent)
      if (event && event.diff) {
        Object.keys(event.diff).filter(key => ![ 'statistics' ].includes(key)).forEach(field => {
          const fieldconfig = config.fields.find(f => f.name === field)
          const diff = merge({}, event.diff)
          if (fieldconfig && fieldconfig.modelname) {
            if (!models_to_fetch[fieldconfig.modelname]) {
              models_to_fetch[fieldconfig.modelname] = []
            }
            const modeldiff = getIn(diff, field)
            if (Array.isArray(modeldiff)) {
              let [ oldval, newval ] = modeldiff
              if (!Array.isArray(oldval)) {
                oldval = [ oldval ]
              }
              if (!Array.isArray(newval)) {
                newval = [ newval ]
              }
              const ids = [ ...oldval, ...newval ]
              models_to_fetch[fieldconfig.modelname] = models_to_fetch[fieldconfig.modelname].concat(ids)
            } else {
              let [ oldval, newval ] = getIn(diff, field)

              if (!Array.isArray(oldval)) {
                oldval = [ oldval ]
              }
              if (!Array.isArray(newval)) {
                newval = [ newval ]
              }
              const ids = [ ...oldval, ...newval ]
              models_to_fetch[fieldconfig.modelname] = models_to_fetch[fieldconfig.modelname].concat(ids)
            }
          }
        })
      }
    })
    Object.keys(models_to_fetch).forEach(modelname => {
      const ids = uniqueArray(models_to_fetch[modelname]).filter(Number.isInteger)
      if (ids.length) {
        const values = {
          modelname,
          params: {
            get_all: 1,
            id__in: ids
          }
        }
        new Promise((resolve, reject) => {
          this.props.fetchMany({
            values,
            resolve,
            reject
          })
        }).catch(e => console.error(e))
      }
    })
  }

  setHasEvents() {
    this.setState({ hasEvents: true })
  }

  loadMore() {
    const { config, model } = this.props
    let params = {
      order_by: '-created',
      modelname: config.modelname,
      id: model ? model.id : null,
      offset: this.state.offset
    }
    if (this.state.searchValues) {
      // Add the search values to the query parameters:
      params = { ...params, ...this.state.searchValues }
    }

    new Promise((resolve, reject) => this.props.fetchActivity(params, resolve, reject)).then(r => {
      const more = !!r.next
      const events = Array.isArray(r) ? r : r.results
      this.setState({ events: [ ...this.state.events, ...events ], more, offset: this.state.offset + events.length })
    })
  }

  getEventMonth(date) {
    const newDate = new Date(date)
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format
    const longName = monthName(newDate) // "July 2018"
    return longName
  }

  getEventDay(date) {
    const newDate = new Date(date)
    const dayname = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format
    const longName = dayname(newDate) // "July 2018"
    return longName
  }


  updateInput(e) {
    this.setState({ term: e.target.value })
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.form.submitForm()
    } // continue typing
    return true
  }

  addVerb(bag, el) {
    const { location } = this.props
    const fields = this.fields.map(field => {
      const props = {
        ...field,
        name: `${field.name}${field.verb ? `__${field.verb}` : ''}`
      }
      if (field.name === 'created__date' && this.state.dates.length) {
        props.enabled = this.state.dates
        props.onChange = bag.submitForm
      }
      if (field.name === 'search') {
        props.suffix = (
          <Button icon="#icon24-Search" type="button" onClick={bag.submitForm} className="input-group-addon btn btn-icon-24 btn-none" />
        )
        props.form_el = el
        props.show_search = true

        props.after = this.state.searched ? (
          <Button
            id="keyword-search-btn"
            tabIndex="-1"
            type="button"
            icon="#icon16-Refresh"
            onClick={() => {
              this.form.resetForm({ term: null, created__date__gte: null, created__date__lte: null })
              this.setState({ searched: false })
              this.form.submitForm()
            }}
            disabled={this.state.searching}
            className="btn btn-grey btn-icon-16"
          />
        ) : null
      }
      return props
    })
    const initvals = {}
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('search')) { initvals.search = qs.getParam('search') }
      if (qs.hasParam('created__date__gte')) { initvals.created__date__gte = qs.getParam('created__date__gte') }
      if (qs.hasParam('created__date__lte')) { initvals.created__date__lte = qs.getParam('created__date__lte') }
    }
    if (!isEqual(this.state.initvals, initvals)) {
      this.setState({ initvals })
    }


    return <FieldGroup
      form={bag}
      card={false}
      match={this.props.match}
      columns
      config={{
        fields
      }}
      fields={fields}
    />
  }

  handleSubmit(values, form) {
    const { config, model } = this.props
    let params = {
      order_by: '-created',
      modelname: config.modelname,
      id: model ? model.id : null
    }
    let searched = false
    this.setState({ events: [] })
    if (values) {
      params = { ...params, ...values }
      searched = Object.keys(values).map(k => values[k]).some(k => k)
      if (searched) {
        // searchValues is used by the Load More button:
        this.setState({ searchValues: values })
      } else {
        this.setState({ searchValues: {} })
      }
    }
    if (values.search) {
      new Promise((resolve, reject) => this.props.fetchActivity({ ...params, fields: [ 'id', 'created' ], get_all: 1 }, resolve, reject)).then(r => {
        const dayGroups = groupBy(r, 'created', this.getEventDay)
        this.setState({ dates: Object.keys(dayGroups) })
      })
    } else {
      new Promise((resolve, reject) => this.props.fetchActivity({
        order_by: '-created',
        modelname: config.modelname,
        id: model.id, fields: [ 'id', 'created' ], get_all: 1 }, resolve, reject)).then(r => {
        const dayGroups = groupBy(r, 'created', this.getEventDay)
        this.setState({ dates: Object.keys(dayGroups) })
      })
    }
    new Promise((resolve, reject) => this.props.fetchActivity(params, resolve, reject)).then(r => {
      form.setSubmitting(false)
      const more = !!r.next
      const events = Array.isArray(r) ? r : r.results
      this.setState({ events, more, offset: events.length, searched })
    }).catch(() => {
      form.setSubmitting(false)
    })
  }

  render() {
    const { cache, config, user, model, settings } = this.props
    const { events } = this.state
    let events_by_date
    if (events) { events_by_date = groupBy(events, 'created', this.getEventMonth) }
    return (
      <div className="activity">
        {events_by_date ? (
          <div className="timeline">
            {this.state.hasEvents &&
              <div className="timeline-bar"></div>
            }
            <div className="timeline-content" ref={el => (this.el = el)}>
              {Object.keys(events_by_date).sort((a, b) => {
                const dateA = new Date(`01 ${a}`)
                const dateB = new Date(`01 ${b}`)
                if (dateA < dateB) {
                  return 1
                }
                if (dateA > dateB) {
                  return -1
                }
                return 0
              }).map((date, did) => {
                let newmonth = true
                if (!this.curmonth) {
                  this.curmonth = date
                }
                let events_by_month = events_by_date[date]
                events_by_month = sortBy(events_by_month, 'created')
                return events_by_month.reverse().map((evt, eidx) => {
                  if (eidx > 0) { newmonth = false }
                  return (
                    <ActivityEvent
                      worker={this.worker}
                      key={`event-${did}-${eidx}`}
                      event={evt}
                      user={user}
                      cache={cache}
                      config={config}
                      date={date}
                      newmonth={newmonth}
                      agents={this.props.agents}
                      fetchOne={this.props.fetchOne}
                      fetchMany={this.props.fetchMany}
                      setHasEvents={this.setHasEvents}
                      model={model}
                      hasEvents={this.state.hasEvents}
                      settings={settings}
                    />
                  )
                })
              })}
              {this.state.more &&
                <div className="list-actions">
                  <Button
                    onClick={() => this.loadMore()}
                    className="btn btn-grey"
                    type="button"
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          </div>
        ) : null}
        {this.state.loading &&
          <div className="text-center">
            <Loader className="large" inline />
          </div>
        }
        <Formik
          initialValues={{
            ...this.state.initvals
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={this.handleSubmit}
          enableReinitialize={false}
        >{ formik => {
            this.form = formik
            return (
              <CustomForm
                component="div"
                className="form"
                render={el => (
                  <div className="activity-search search-fields">
                    {this.addVerb(formik, el)}
                    {this.state.searched &&
                      <div className="reset-group">
                      </div>
                    }
                  </div>
                )}
              />
            )
          }}
        </Formik>
      </div>
    )
  }
}

Activity.propTypes = {
  model: PropTypes.object,
  events: PropTypes.array,
  config: PropTypes.object,
  agents: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  fetchActivity: PropTypes.func,
  fetchOne: PropTypes.func,
  fetchMany: PropTypes.func
}

export default withCustomRouter(Activity)
