/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { NavLink } from 'react-router-dom'
import isEqual from 'react-fast-compare'

import { CONFIG } from '../../selectors'
import { parseURL } from '../../utils'
import { Button } from '../ui/Button'
import MetaDetail from './MetaDetail'
import Tip from './forms/Tip'


class ContactDetails extends React.Component {
  constructor(props) {
    super(props)
    if (props.match.params.model === 'referrals') {
      this.state = {
        fields: getIn(props, 'config.fields', []).filter(f => [
          'first_name',
          'last_name',
          'email',
          'cell_number'
        ].map(fe => JSON.stringify(fe)).includes(JSON.stringify(f.name)))
      }
    } else {
      this.state = {
        fields: getIn(props, 'config.fields', []).filter(f => [
          [ 'first_name', 'last_name' ],
          'status',
          'email',
          'cell_number',
          'linked_contacts',
          'directors',
          'spouse'
        ].map(fe => JSON.stringify(fe)).includes(JSON.stringify(f.name)))
      }
    }
  }

  componentDidMount() {
    const { match, user, model, actions } = this.props
    if (match.params.model === 'referrals' || !user.permissions.includes('referral_can_view_contact') && [ 'Pending', 'Declined' ].includes(model.status)) {
      const vals = {
        contact: model.contact,
        branch: model.recipient_branch
      }
      return new Promise((resolve, reject) => {
        actions.fetchReferralContactMatch({ values: vals, resolve, reject })
      }).then(r => {
        this.setState({ match: r })
      }).catch(e => {
        console.error(e)
      })
    }
  }

  render() {
    const { label, contact, region, disabled, user, match, canEdit } = this.props
    if (!contact) { return null }
    if (disabled && [ 'ae' ].includes(region)) {
      return 'You do not have permission to view this contact'
    }
    if (match.params.model !== 'referrals') {
      return this.state.fields.map((field, fidx) => {
        let value = getIn(contact, field.name)
        if (!value && Array.isArray(field.name)) {
          value = field.name.map(fe => getIn(contact, fe)).join(field.labelseperator || ' ')
        }
        let url
        if (!value) { return null }
        if (Array.isArray(value) && !value.length) { return null }
        if (field.multi && field.input === 'ContactLookup') {
          const meta = contact.meta[field.name]
          let lab = field.label
          if (lab === 'Link Contact') { lab = 'Linked Contact' }
          return value.map((v, vid) => {
            const val = getIn(meta, vid)
            return (
              <ContactDetails key={`contacts-contact-${fidx}-${vid}`} {...this.props} contact={val} label={`${label} ${lab} ${vid + 1}`} />
            )
          }).filter(node => node)
        } else if (field.input === 'ContactLookup') {
          const val = contact.meta[field.name]
          let lab = field.label
          if (lab === 'Link Contact') { lab = 'Linked Contact' }
          return (
            <ContactDetails {...this.props} key={`contacts-contact-${fidx}`} label={`${label} ${lab}`} contact={val} />
          )
        }
        if (!disabled && canEdit) {
          if (field.link) {
            url = parseURL(field.link, contact)
          } else if (field.name === 'first_name') {
            url = parseURL('/secure/:site/contacts/:id', contact)
          }
        }
        if (disabled && ![ 'first_name', 'last_name' ].includes(field.name) && !isEqual([ 'first_name', 'last_name' ], field.name)) {
          return null
        }
        if (!canEdit && ![ 'first_name', 'last_name' ].includes(field.name) && !isEqual([ 'first_name', 'last_name' ], field.name)) {
          return null
        }
        if (field.format === 'contact_whatsapp_link') {
          field.modelname = this.props.modelname
          field.model = this.props.model
          value = contact
        }
        return (
          <MetaDetail
            key={`contact-f${fidx}`}
            className={field.detailCols ? `col-${field.detailCols}` : `${field.cols ? `col-${field.cols}` : 'col-lg-6'}`}
            label={`${label} ${field.label}`}
            value={value}
            format={field.format}
            field={field}
            url={url}
          />
        )
      }).filter(node => node)
    }
    if (match.params.model === 'referrals' &&
      this.state.match && [ 'Accepted', 'Pending', 'Declined' ].includes(this.props.model.status)) {
      const referral_fields = [ 'first_name', 'last_name', 'email', 'cell_number' ]
      const matches = Object.values(this.state.match).reduce((a, item) => a + item, 0)
      return ([
        (matches > 1 || this.state.match.email || this.state.match.cell_number) && this.props.model.status === 'Pending' ? <div className='meta-detail col-lg-12'>
          <Tip
            heading={false}
            classes="alert tip-input"
            key='referral-tip'
            text='It looks like you might already have this contact in your office. Please refer to the details below before accepting or declining this referral.'
          />
        </div> : null,
        this.state.fields.map((field, fidx) => {
          if (referral_fields.includes(field.name)) {
            let value = getIn(contact, field.name)
            if (!value && referral_fields.includes(field.name)) { value = this.state.match[field.name] }
            let url
            return (
              <MetaDetail
                key={`contact-f${fidx}`}
                className={'col-lg-12'}
                label={`${label} ${field.label}`}
                value={value}
                format='referral-contact'
                field={{ ...field, ...this.state.match, permissions: user.permissions }}
                url={url}
              />
            )
          }
          return null
        }),
        user.permissions.includes('referral_can_view_contact') ||
        user.permissions.includes('is_prop_data_user') ||
        user.permissions.includes('apply_to_all_branches') ||
        user.permissions.includes('referral_can_view_created_contact') ?
          <Button
            component={NavLink}
            to={`/secure/${user.agent.site.id}/contacts/${user.permissions.includes('referral_can_view_created_contact') ? this.props.model.created_contact : this.props.model.contact}/details`}
            className="btn btn-subtle"
          >View Contact</Button> : null
      ]).filter(node => node)
    }
    return null
  }
}


const mapStateToProps = state => ({
  config: CONFIG(state, 'contacts')
})

ContactDetails.propTypes = {
  contact: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  config: PropTypes.object,
  model: PropTypes.object,
  actions: PropTypes.object,
  match: PropTypes.object,
  region: PropTypes.string,
  modelname: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  canEdit: PropTypes.bool,
  user: PropTypes.object
}

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ContactDetails))
