/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { UI } from '../../../selectors'


const WideSidebar = props => {
  const [ open, setOpen ] = useState(false)
  const [ root, setRoot ] = useState(document.getElementById('wrapper'))
  const prevProps = useRef(props)

  useEffect(() => {
    setRoot(document.getElementById('wrapper'))
  }, [])

  useEffect(() => {
    if (
      props.ui.sidebar
      && prevProps.current.ui.sidebar !== props.ui.sidebar
      && props.ui.sidebar === props.sidebar
    ) {
      setOpen(true)
    } else if (prevProps.current.ui.sidebar !== props.ui.sidebar && props.ui.sidebar !== props.sidebar) {
      setOpen(false)
    }
    prevProps.current = props
    return () => { }
  }, [ props ])

  if (root) { // Prevent crash during redirects
    return ReactDOM.createPortal(
      <CSSTransition
        in={open}
        timeout={300}
        classNames="sidebar-right wide-sidebar"
        unmountOnExit={[ null, undefined ].includes(props.unmountOnExit) ? true : props.unmountOnExit}
      >{props.children}</CSSTransition>,
      root
    )
  }
  return null
}

const mapStateToProps = state => {
  const ui = UI(state)
  return {
    ui
  }
}

WideSidebar.propTypes = {
  ui: PropTypes.object,
  sidebar: PropTypes.string,
  unmountOnExit: PropTypes.bool,
  children: PropTypes.node
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(WideSidebar))
