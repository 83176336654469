import decodeComponent from 'decode-uri-component'


class QueryBuilder {
  // optionally pass a querystring to parse
  constructor(querystring, encode = false) {
    this.querystring = querystring
    this.params = {}
    this.root = ''
    this.hash = ''
    this.parseQueryString()
    this.encode = encode
  }

  parseQueryString() {
    if (![ 'object', 'string' ].includes(typeof this.querystring) || Array.isArray(this.querystring)) {
      throw new Error(`Cannot instantiate QueryBuilder with ${Array.isArray(this.querystring) ? 'array' : typeof this.querystring}`)
    }
    if (typeof this.querystring === 'object' && Object.keys(this.querystring).length >= 1) { // parse object
      this.setParam(this.querystring)
    } else if (this.querystring.indexOf('?') !== -1) { // parse querystring and set the root
      const qs = this.querystring.split('?')
      if (qs.length > 1) {
        this.root = qs[0]
      }
      this.params = this.parse(qs.pop())
    } else if (this.querystring.indexOf('=') !== -1) { // parse part of querystring
      this.params = this.parse(this.querystring)
    } else { // don't parse anything, just set the root
      this.root = this.querystring
    }
    const hash_parts = this.root.split('#')
    if (hash_parts.length > 1) {
      this.root = hash_parts[0]
      this.hash = hash_parts[1]
    }
  }

  parse(string) {
    let qs = string.trim().replace(/^[?#&]/, '')
    qs = decodeComponent(qs)

    const args = qs.split('&')

    for (let i = 0; i < args.length; i++) {
      const [ arg, value ] = args[i].split('=').map(prop => encodeURIComponent(prop))
      if (value) {
        let val = decodeComponent(value)
        if (value.indexOf(',') !== -1 && value.indexOf(', ') === -1) {
          val = value.split(',').map(v => (isNaN(v) ? v : parseInt(v, 10)))
        }
        this.setParam(arg, val)
      }
    }
    return this.params
  }

  getParam(param, default_) {
    // Get the value of an query parameter OR return a default string
    let value = this.params[param]

    if (value === null || value === undefined) { return default_ || '' }
    value = decodeComponent(value)
    if ([ '#', '?' ].includes(value)) {
      value = encodeURIComponent(value)
    }
    if (value.indexOf(',') !== -1 && value.indexOf(', ') === -1) {
      if (value.indexOf('.') !== -1) {
        value = value.split(',').map(v => (isNaN(v) ? v : parseFloat(v)))
      } else {
        value = value.split(',').map(v => (isNaN(v) ? v : parseInt(v, 10)))
      }
    }
    if (value.indexOf('%2C') !== -1) {
      if (value.indexOf('.') !== -1) {
        value = value.split('%2C').map(v => (isNaN(v) ? v : parseFloat(v)))
      } else {
        value = value.split('%2C').map(v => (isNaN(v) ? v : parseInt(v, 10)))
      }
    }
    if (!isNaN(value) && !value.startsWith('0')) {
      value = parseInt(value, 10)
    }
    if (value === 'true') {
      value = true
    }
    if (value === 'false') {
      value = false
    }
    if (param.indexOf('_overlap') !== -1 && (String(value).indexOf(',') !== -1 || String(value).indexOf('{') !== -1)) {
      if (!Array.isArray(value)) {
        value = value.replace('{', '').replace('}', '').split(',')
      }
    }
    return this.encode ? encodeURIComponent(value) : value
  }

  setParam(param, value = null) {
    if (param === null || typeof param === 'undefined') { return }
    try {
      if (typeof param === 'object') { // Update params with new object
        for (const p in param) {
          if (param[p]) {
            let val = param[p]
            if (val === null || typeof param === 'undefined') {
              val = null
            } else if (Array.isArray(val)) {
              val = val.join(',')
            } else if (typeof val === 'object') {
              val = JSON.stringify(val)
            }
            if (p.indexOf('_overlap') !== -1 && (String(val).indexOf(',') !== -1 || String(val).indexOf('{') !== -1)) {
              val = val.replace('{', '').replace('}', '').split(',')
            }
            this.params[p] = (val !== null) ? encodeURIComponent(val) : val
          }
        }
      } else if (param.indexOf('?') !== -1) { // If `param` is another querystring, extract the params
        this.setParam(this.parse(param))
      } else { // set the param to the supplied value
        if (param.indexOf('_overlap') !== -1 && (String(value).indexOf(',') !== -1 || String(value).indexOf('{') !== -1)) {
          if (!Array.isArray(value)) {
            value = value.replace('{', '').replace('}', '').split(',')
          }
        }
        if (Array.isArray(value)) { value = value.join(',') }
        if (value === 'null') { value = null }
        this.params[param] = (value !== null) ? encodeURIComponent(value) : value
      }
    } catch (e) {
      console.error(e)
      throw new Error(`Error setting parameter ${param} in QueryBuilder`)
    }
  }

  hasParam(param) { // Check if an parameter exists
    return this.params.hasOwnProperty(param)
  }

  removeParam(param) {
    try { // Check if an parameter exists
      delete this.params[param]
    } catch (e) {
      throw new Error(`Error deleting parameter ${param} from QueryBuilder`)
    }
  }

  getAllArgs(allowBlank = true) {
    const params = {}
    for (const param in this.params) {
      if (allowBlank) {
        if (this.hasParam(param)) {
          params[param] = this.getParam(param)
        }
      } else if (this.getParam(param) !== '') {
        params[param] = this.getParam(param)
      }
    }
    return params
  }

  url(allowBlank = true, add_slash = false) {
    const args = []
    for (const param in this.params) {
      if (allowBlank) {
        if (param.indexOf('_overlap') !== -1) {
          args.push(`${param}={${this.getParam(param, '')}}`)
        } else {
          args.push(`${param}=${this.getParam(param, '')}`)
        }
      } else if (this.params[param]) {
        if (param.indexOf('_overlap') !== -1) {
          args.push(`${param}={${this.getParam(param)}}`)
        } else {
          args.push(`${param}=${this.getParam(param)}`)
        }
      }
    }
    return `${this.root}${(add_slash && !this.root.endsWith('/')) ? '/' : ''}${args.length ? `?${args.join('&')}` : ''}${this.hash ? `#${this.hash}` : ''}`
  }
}

export default QueryBuilder
