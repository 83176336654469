import FileSaver from 'file-saver'
import { Formik } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import { buildLambdaURI, valueFormat } from '../../utils'
import { Button } from '../ui/Button'
import Loader from './Loader'
import Card from './Card'
import Checkbox from './datatable/Checkbox'
import CustomForm from './forms/CustomForm'
import { FILES_LIST, IMAGE_LIST } from './forms/inputs/FileDropzone'


const SelectableMedia = ({ item, idx, parms, form }) => {
  const srcurl = buildLambdaURI(item.file, parms)
  const id__in = Array.from(form.values.id__in)
  return (
    <div className="image-gallery-thumb">
      <div className="image-gallery-thumb-img-size">{valueFormat('filesize', item.file_size)}</div>
      <a href={item.file} target="_blank" rel="noopener noreferrer" id={`gallery-image-${idx}-a`}>
        <img
          id={`image-${item.id}`}
          style={{ visbility: 'hidden' }}
          key={`image-${item.id}`}
          alt={item.caption}
          title={item.caption}
          src={srcurl}
          onLoad={() => {
            const containerid = document.getElementById(`gallery-image-${idx}-a`)
            containerid.style.background = 'none'
            const imgid = document.getElementById(`image-${item.id}`)
            imgid.style.visibility = 'visible'
          }} />
      </a>
      <div className="image-gallery-thumb-meta">
        <div className="checkinput form-group">
          <Checkbox
            id={`id__in-${idx}`}
            name={'id__in'}
            val={item.id}
            component={'input'}
            clickAction={() => {
              if (!form.values.id__in.includes(item.id)) {
                id__in.push(item.id)
              } else if (form.values.id__in.includes(item.id)) {
                const pos = id__in.indexOf(item.id)
                id__in.splice(pos, 1)
              }
              form.setFieldValue('id__in', id__in)
            }}
            selected={(form.values.id__in) ? form.values.id__in.includes(item.id) : false}
          />
        </div>
        {(item.caption || item.tagged_date) && <div className="image-gallery-caption">{item.caption}{item.caption && item.tagged_date && ' | '}{item.tagged_date && `${item.tagged_date}`}</div>}
      </div>
    </div>
  )
}

SelectableMedia.propTypes = {
  item: PropTypes.object,
  idx: PropTypes.number,
  parms: PropTypes.object,
  form: PropTypes.object
}

class SelectableGallery extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.state = {
      items: props.items.filter(m => m)
    }
  }

  componentDidMount() {
    const { items } = this.props
    this.setState({ items: items.filter(m => m) })
  }

  handleSubmit(values, formik_actions) {
    const { actions } = this.props
    new Promise((resolve, reject) => {
      formik_actions.setSubmitting(true)
      return actions.downloadImages({ values: { selected: values.id__in, filename: values.filename }, resolve, reject })
    }).then(r => {
      formik_actions.setSubmitting(false)
      const blob = new Blob([ r ], { type: 'application/zip' })
      const url = window.URL.createObjectURL(blob)

      const downloadByUrl = () => {
        this.downloadManager.downloadUrl(url, values.filename)
      }
      try {
        this.downloadManager.download(blob, url, values.filename)
      } catch (e) {
        downloadByUrl()
      }
    }).catch(() => {
      formik_actions.setSubmitting(false)
    })
  }

  render() {
    const { midx, label, mediatype, parms, model, modelname, is_print } = this.props
    const { items } = this.state
    const total_size = items.map(item => item.file_size).reduce((acc, val) => acc + val)
    let img_total_size_text = ''
    let total_size_text = ''
    let p24_active = ''
    if ([ 'residential', 'commercial', 'holiday', 'project' ].includes(modelname) && !is_print) {
      if (model.meta.portals) {
        p24_active = model.meta.portals.find(p => p.portal === 1 && p.active)
        img_total_size_text = p24_active ? <b>Property24 Limit:</b> : <b>Total Size:</b>
        total_size_text = p24_active ? `${valueFormat('filesize', total_size)} / 60MB` : valueFormat('filesize', total_size)
      }
    }
    return (
      <Formik
        initialValues={{
          id__in: [],
          filename: `${modelname}_${model.id}_images.zip`
        }}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >{ formik => {
          this.form = formik
          return (
            <CustomForm
              render={() => (
                <Card
                  key={`${mediatype}-gallery-${midx}`}
                  background
                  header={
                    <>
                      <h3 className="flex-heading">{label}</h3>
                      {label &&
                        <div className="details-section-buttons">
                          {(formik.values.id__in && formik.values.id__in.length < items.length) ? (
                            <Button
                              type="button"
                              icon="#icon16-Check"
                              className={`btn btn-subtle btn-icon btn-icon-16 ${is_print ? 'print-card-no-display' : ''}`}
                              onClick={() => {
                                const ids = items.filter(i => i).map(i => i.id)
                                formik.setFieldValue('id__in', ids)
                                formik.setFieldTouched('id__in')
                              }}
                            >Select All</Button>
                          ) : null}
                          { (formik.values.id__in && formik.values.id__in.length > 0) ? (
                            <>
                              <Button
                                type="button"
                                className='btn btn-subtle'
                                onClick={() => {
                                  formik.setFieldValue('id__in', [])
                                  formik.setFieldTouched('id__in')
                                }}
                              >Unselect All</Button>
                              <Button
                                type="button"
                                icon="#icon16-Download"
                                className='btn btn-primary btn-icon btn-icon-16'
                                onClick={() => {
                                  formik.handleSubmit()
                                }}
                              >Download Selected</Button>
                            </>
                          ) : null}
                        </div>
                      }
                      {[ 'residential', 'commercial', 'holiday', 'project' ].includes(modelname) ?
                        <div className="mg-collative-img-size">{img_total_size_text}&nbsp;{total_size_text}</div>
                        : null
                      }
                    </>
                  }
                  body={formik.isSubmitting ? (
                    <Loader />
                  ) : (
                    <div className={`${mediatype}-gallery`} key={`gallery-${mediatype}`}>
                      {items.filter(i => i).map((item, idx) => (
                        <SelectableMedia
                          key={`gallery-image-${idx}`}
                          item={item}
                          idx={idx}
                          parms={parms}
                          form={formik}
                        />
                      ))}
                    </div>
                  )}
                />
              )}
            />
          )
        }}
      </Formik>
    )
  }
}

SelectableGallery.propTypes = {
  items: PropTypes.array,
  idx: PropTypes.number,
  parms: PropTypes.object,
  form: PropTypes.object,
  midx: PropTypes.number,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  mediatype: PropTypes.string,
  model: PropTypes.object,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  is_print: PropTypes.bool
}


const MediaGallery = ({ media, model, modelname, config, actions, is_print }) => {
  if (!media || !config) { return null }
  const parms = { w: 640, h: 480 }
  const gallery = media.map((mediagroup, midx) => {
    const { items, mediatype, label, plural, fieldname } = mediagroup
    const field = config.fields.find(f => f.name === fieldname)
    const mockform = {
      touched: { [field.name]: true },
      values: { [field.name]: items ? items.filter(m => m).map(m => m.id) : [] }
    }
    if (field.permission_key) {
      if (Array.isArray(field.permission_key)) {
        field.permission_key.forEach(k => {
          mockform.values[k] = model[k]
        })
      } else {
        mockform.values[field.permission_key] = model[field.permission_key]
      }
    }
    if (field.edit && Array.isArray(field.edit)) {
      field.edit.map(s => s.map(condition => (
        mockform.values[condition.field] = model[condition.field]
      )))
      if (!actions.isConditional(field, 'edit', false, mockform)) { return null }
    }
    if ((!items || items.filter(i => i).length === 0) && mediatype !== 'mixed') {
      let can_edit = true
      if (field.readonly && Array.isArray(field.readonly)) {
        field.readonly.map(s => s.map(condition => (
          mockform.values[condition.field] = model[condition.field])
        ))
        if (!actions.isConditional(field, 'readonly', false, mockform)) { can_edit = false }
      }
      return (
        <Card
          key={`${mediatype}-gallery-${midx}`}
          background
          header={
            <h3>{label}</h3>
          }
          body={
            <div className="mediatype" key={`${mediatype}-gallery-${midx}`}>
              <div>
                There are no {`${plural}`} for this {config.singular}.&nbsp;
                {can_edit && !is_print &&
                  <NavLink
                    className="has-link"
                    to={`/secure/${model.site}/${modelname}/${model.id}/edit#field-${fieldname}`}
                    scroll={el => setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'center' }), 3000) }
                  >Add some now.
                  </NavLink>
                }
              </div>
            </div>
          }
        />
      )
    }
    if (!items || items.filter(i => i).length === 0) { return null }
    if (mediatype === 'mixed') {
      return (
        <div key={`${mediatype}-gallery-${midx}`}>
          {label &&
            <div className="row">
              <h4 className={`col details-section-heading${midx === 0 ? ' no-border' : ''}`}>{label}</h4>
            </div>
          }
          <div className={`${mediatype}-gallery`} key={`gallery-${mediatype}`}>
            {items.map((item, idx) => {
              let icon = ''
              if (FILES_LIST.includes(item.content_type)) {
                icon = valueFormat('filetype', item.content_type)
              }
              return (
                <div className={`${mediatype}-gallery-thumb`} key={`gallery-${mediatype}-${idx}`}>
                  <a href={item.file} target="_blank" rel="noopener noreferrer" id={`gallery-${mediatype}-${idx}-a`}>
                    {IMAGE_LIST.includes(item.content_type) ? (
                      <img
                        id={`${mediatype}-${item.id}`}
                        style={{ visbility: 'hidden' }}
                        src={buildLambdaURI(item.file, { w: 640, h: 480 })}
                        alt={item.caption}
                        key={`image-${item.id}`}
                        title={item.caption}
                        onLoad={() => {
                          const containerid = document.getElementById(`gallery-${mediatype}-${idx}-a`)
                          containerid.style.background = '#FFFFFF'
                          const imgid = document.getElementById(`${mediatype}-${item.id}`)
                          imgid.style.visibility = 'visible'
                        }}
                      />
                    ) : (
                      <div className="img icon">
                        <a title={item.caption} href={item.file} target="_blank" rel="noopener noreferrer">
                          {icon}
                        </a>
                      </div>
                    )}
                    {(item.caption || item.tagged_date) && <div className={`${mediatype}-gallery-caption`}>{item.caption}{item.caption && item.tagged_date && ' | '}{item.tagged_date && `${item.tagged_date}`}</div>}
                  </a>
                </div>
              )
            }
            )}
          </div>
        </div>
      )
    }
    if (mediatype === 'image') {
      return (
        <SelectableGallery
          key={`${mediatype}-gallery-${midx}`}
          midx={midx}
          label={label}
          mediatype={mediatype}
          model={model}
          modelname={modelname}
          items={items}
          parms={parms}
          actions={actions}
          is_print={is_print || false}
        />
      )
    }
    return (
      <Card
        key={`${mediatype}-gallery-${midx}`}
        background
        header={
          <h3>{label}</h3>
        }
        body={
          <table className={`${mediatype}-gallery`} key={`gallery-${mediatype}`}>
            <thead>
              <tr>
                <th className="file-name">Document Name</th>
                <th className="file-size">Size</th>
                <th className="file-type">Type</th>
              </tr>
            </thead>
            <tbody>
              {items.filter(i => i).map((item, idx) => {
                let filename = item.caption
                if (!filename) {
                  if (typeof item.file === 'string') {
                    const parts = item.file.split('/').pop().split('.')
                    parts.pop()
                    filename = parts.join('.')
                  } else if (item.filename) {
                    const parts = item.filename.split('.')
                    parts.pop()
                    filename = parts.join('.')
                  }
                }
                return (
                  <tr key={`fr-${idx}`} className="file-item">
                    <td className="file-name">
                      <button type="button" onClick={() => {
                        const ext = item.file.split('/').pop().split('.').pop()
                        FileSaver.saveAs(item.file, `${filename}.${ext}`)
                      }} className="btn btn-none">
                        {filename}
                      </button>
                    </td>
                    <td className="file-size">{valueFormat('filesize', item.file_size)}</td>
                    <td className="file-type">{valueFormat('filetype', item.content_type)}</td>
                  </tr>
                )
              }) }
            </tbody>
          </table>
        }
      />
    )
  })
  return gallery
}

MediaGallery.propTypes = {
  model: PropTypes.object,
  actions: PropTypes.object,
  modelname: PropTypes.string,
  media: PropTypes.array,
  config: PropTypes.object.isRequired,
  is_print: PropTypes.bool
}

export default MediaGallery
